import { ModalCustom } from 'components/ModalCustom';
import './style.scss';

type props = {
  onClose: () => void;
  isOpen: boolean;
  handleTryAgain: () => void;
};

export const ModalSubscriptionError = ({ onClose, isOpen, handleTryAgain }: props) => {
  return (
    <ModalCustom
      onClose={onClose}
      toggle={onClose}
      isOpen={isOpen}
      className="modal-blur modal-lg modal-authen-methods">
      <div className="modal-authen-methods-error">
        <div className="modal-authen-methods--title">Subscription Unsuccessful!</div>
        <div className="modal-authen-methods--text">
          Your payment was not processed. Please use a valid credit card.
        </div>
        <div className="modal-authen-methods--btns">
          <button className="modal-authen-methods--btn" onClick={onClose}>
            Cancel
          </button>
          <button className="modal-authen-methods--btn" onClick={handleTryAgain}>
            Try Again
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};
