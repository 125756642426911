import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './style.scss';
import logo from 'assets/images/logo.svg';
import slide1 from 'assets/images/Uthrive-slide1.png';
import slide2 from 'assets/images/Uthrive-slide2.png';
import slide3 from 'assets/images/Uthrive-slide3.png';
import slide4 from 'assets/images/Uthrive-slide4.png';
import add from 'assets/images/add.png';
import pre from 'assets/images/back.png';
import next from 'assets/images/next.png';
import { linkDownLoadExtension } from 'const';
import { getLinkDownloadExtensionByBrowser } from 'utils/extension';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useAuthStore } from 'store';

export const ExtensionThanks = () => {
  const { token } = useAuthStore();

  return (
    <div className="extension-thank-wrapper">
      <div className="extension-thank-wrapper--header">
        <div className="extension-thank-wrapper--header-logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        {token && (
          <div className="uninstall-extension-wrapper--header-button">
            <a href={'/'} target="_self" rel="noreferrer">
              <button>Back to home</button>
            </a>
          </div>
        )}
      </div>
      <div className="extension-thank-wrapper--body">
        <div className="extension-thank-wrapper--body-title">
          <h1>An Effortless Way to Get Maximum Savings & Rewards.</h1>
        </div>
        <div className="carousel-wrapper">
          <Carousel
            autoPlay={false}
            centerMode={true}
            centerSlidePercentage={100}
            infiniteLoop={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            renderArrowPrev={(clickHandler) => {
              return (
                <div className="arrow-icon-pre" onClick={clickHandler}>
                  <img src={pre} alt="pre" className="icon-slide" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler) => {
              return (
                <div className="arrow-icon-next" onClick={clickHandler}>
                  <img src={next} alt="next" className="icon-slide" />
                </div>
              );
            }}>
            <img className="slide-item" src={slide1} alt="slide-img" />
            <img className="slide-item" src={slide2} alt="slide-img" />
            <img className="slide-item" src={slide3} alt="slide-img" />
            <img className="slide-item" src={slide4} alt="slide-img" />
          </Carousel>
        </div>

        <div className="extension-thank-wrapper--body-bottom">
          <i className="extension-thank-wrapper--body-bottom-text">
            “Easily put hundreds of dollars back into your pocket.”
          </i>
          <div>
            <a
              href={linkDownLoadExtension.find((e) => e.key === getLinkDownloadExtensionByBrowser())?.value}
              target="_blank"
              rel="noreferrer">
              <button className="extension-thank--btn">
                <img src={add} alt="icon" />
                Reinstall Uthrive
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
