import { Spinner } from 'reactstrap';
import './style.scss';
import { useAppStore } from 'store';

export const AppLoading = () => {
  const { loadingMessage } = useAppStore();
  return (
    <>
      <div className="spinner-container">
        <Spinner
          color="primary"
          style={{
            height: '3rem',
            width: '3rem',
            textAlign: 'center'
          }}
        />
        {loadingMessage && <div className="loading-text">{loadingMessage}</div>}
      </div>
      <div className="backdrop" />
    </>
  );
};
