import { Plan, StripeConfig, Subscription, UserSubscriptionConfig } from 'types';
import axiosClient from './axiosClient';
import {
  BankOffersParamsType,
  IDataCancelSubscription,
  IDataCreateSubscription,
  IDataReactivateSubscription,
  IDataUpdatePaymentMethodSubscription,
  IDataUpdatePlanSubscription,
  StoreOffersParamsType
} from 'types/premium';
import { endpoints } from './endpoints';

export const getStripeConfig = async (): Promise<StripeConfig> => {
  const resp = await axiosClient.get('stripe/config');
  return resp.data;
};

export const getPlans = async (): Promise<Plan[]> => {
  const resp = await axiosClient.get('products');
  return resp.data;
};

export const getMySubscriptions = async (): Promise<Subscription[]> => {
  const resp = await axiosClient.get('user-subscriptions/me');
  return resp.data;
};

export const getUserSubscriptionConfig = async (): Promise<UserSubscriptionConfig> => {
  const resp = await axiosClient.get('user-subscriptions/config');
  return resp.data;
};

export const createSubscription = async (data: IDataCreateSubscription): Promise<any> => {
  const resp = await axiosClient.post('user-subscriptions', data);
  return resp.data;
};

export const getIntentKey = async () => {
  const resp = await axiosClient.post('stripe/payment-sheet');
  return resp.data;
};

export const getPaymentMethods = async () => {
  const resp = await axiosClient.get('user-payment-methods/me');
  return resp.data;
};

export const requestCancelSubscription = async (): Promise<{ success: boolean }> => {
  const resp = await axiosClient.post('user-subscriptions/request-cancel');
  return resp.data;
};

export const confirmCancelSubscription = async (data: IDataCancelSubscription) => {
  const resp = await axiosClient.patch(`user-subscriptions/${data.subscriptionId}/confirm-cancel`, {
    otp: data.otp,
    cancelReason: data.cancelReason
  });
  return resp.data;
};

export const getInvoices = async ({ userSubscriptionId }: { userSubscriptionId: string }) => {
  const resp = await axiosClient.get(`user-subscriptions/${userSubscriptionId}/invoices`);
  return resp.data;
};

export const updatePlan = async (data: IDataUpdatePlanSubscription) => {
  const resp = await axiosClient.patch(`user-subscriptions/${data.subscriptionId}`, {
    userSubscriptionPriceId: data.userSubscriptionPriceId
  });
  return resp.data;
};

export const updatePaymentMethod = async (data: IDataUpdatePaymentMethodSubscription) => {
  const resp = await axiosClient.patch(`user-subscriptions/${data.subscriptionId}`, {
    userSubscriptionPaymentMethodId: data.userSubscriptionPaymentMethodId
  });
  return resp.data;
};

export const reactivateSubscription = async (data: IDataReactivateSubscription) => {
  const resp = await axiosClient.patch(`user-subscriptions/${data.subscriptionId}/reactivate`);
  return resp.data;
};

export const getBankOfferCategories = async () => {
  const resp = await axiosClient.get(endpoints.bankOfferCategories);
  return resp.data?.data;
};

export const getStoreOfferCategories = async () => {
  const resp = await axiosClient.get(endpoints.storeOfferCategories);
  return resp.data?.data;
};

export const getListBankOffers = async (params: BankOffersParamsType) => {
  const resp = await axiosClient.get(endpoints.bankOffers, { params });
  return resp.data;
};

export const getListStoreOffers = async (params: StoreOffersParamsType) => {
  const resp = await axiosClient.get(endpoints.storeOffers, { params });
  return resp.data;
};

export const countBankOffers = async () => {
  const resp = await axiosClient.get(endpoints.countBankOffers);
  return resp.data;
};

export const countStoreOffers = async () => {
  const resp = await axiosClient.get(endpoints.countStoreOffers);
  return resp.data;
};

export const getBanks = async () => {
  const resp = await axiosClient.get(endpoints.banks);
  return resp.data;
};

export const favoriteBankOffer = async (offerId: string) => {
  const resp = await axiosClient.put(endpoints.favBank, { offerId });
  return resp.data;
};

export const favoriteStoreOffer = async (offerId: string) => {
  const resp = await axiosClient.patch(`merchant-offers/${offerId}/favorites/add`);
  return resp.data;
};

export const removeFavoriteStoreOffer = async (offerId: string) => {
  const resp = await axiosClient.patch(`merchant-offers/${offerId}/favorites/remove`);
  return resp.data;
};
