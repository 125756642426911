import {
  EarnedRewardDetailParamsType,
  FeedbackEarnedRewardParamsType,
  MissedRewardDetailParamsType,
  MissedRewardParamsType,
  SeeMoreEarnedParamsType,
  SeeMoreMissedParamsType
} from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

export const getMissedRewards = async (params?: MissedRewardParamsType) => {
  const resp = await axiosClient.get(endpoints.missedRewards, { params });
  return resp.data;
};

export const getEarnedRewards = async (params?: MissedRewardParamsType) => {
  const resp = await axiosClient.get(endpoints.earnedRewards, { params });
  return resp.data;
};

export const getMissedRewardDetail = async (params: MissedRewardDetailParamsType) => {
  const resp = await axiosClient.get(endpoints.missedRewardDetail, { params });
  return resp.data;
};

export const getMoreMissedRewards = async (params: SeeMoreMissedParamsType) => {
  const resp = await axiosClient.get(endpoints.missedRewards, { params });
  return resp.data;
};

export const getEarnedRewardDetail = async (params: EarnedRewardDetailParamsType, id: string) => {
  const resp = await axiosClient.get(`${endpoints.earnedRewards}/${id}`, { params });
  return resp.data;
};

export const getMoreEarnedRewards = async (params: SeeMoreEarnedParamsType) => {
  const resp = await axiosClient.get(endpoints.earnedRewards, { params });
  return resp.data;
};

export const feedbackEarnedReward = async (params: FeedbackEarnedRewardParamsType) => {
  const resp = await axiosClient.post(endpoints.wrongRewardEarn, { ...params });
  return resp.data;
};

export const getMissedFeedbackByTransactionID = async (id: string) => {
  const resp = await axiosClient.get(`${endpoints.wrongRewardEarn}/${id}/MISSED`);
  return resp.data;
};

export const getEarnedFeedbackByTransactionID = async (id: string) => {
  const resp = await axiosClient.get(`${endpoints.wrongRewardEarn}/${id}/EARNED`);
  return resp.data;
};
