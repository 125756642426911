import * as React from 'react';
import './style.scss';

type props = {
  handleClickBtnLogin: () => void;
  isHideTxt?: boolean;
};

export const SignUpStep1Header = (props: props) => {
  const { handleClickBtnLogin, isHideTxt = false } = props;
  return (
    <div className="mb-4 step1-grp">
      <h5 className="title">Create your account.</h5>
      <div className="subTitle">
        Do you already have an account?{' '}
        <span onClick={handleClickBtnLogin} className="text-link">
          Log in here.
        </span>
      </div>
      {!isHideTxt && <div className="notice-text">For your convenience, we will send a 6-digit code.</div>}
    </div>
  );
};
