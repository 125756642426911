import React, { useEffect, useState } from 'react';
import './style.scss';
import logo from 'assets/images/logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { sendRatingEmail, updateRatingEmail } from 'services';
import { useAppStore } from 'store';
import { toast } from 'react-toastify';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const EmailRating = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const { setAppLoading } = useAppStore();

  const [textValue, setTextValue] = useState('');
  const [id, setId] = useState(null);

  useEffect(() => {
    const email = query.get('email');
    const notificationType = query.get('notificationType');
    const rate = parseInt(query.get('rate') || '0');

    if (!email || !rate || !notificationType) {
      navigate('/rating/thanks');
      return;
    }

    if (rate > 5 || rate < 1) {
      navigate('/rating/thanks');
      return;
    }
    const params = {
      emailAddress: email?.replaceAll(' ', '+'),
      rating: rate,
      feedback: '',
      notificationType: notificationType,
      deviceType: isMobile ? 'MOBILE' : 'DESKTOP'
    };

    sendRatingEmail(params)
      .then((res: any) => {
        setId(res?.data?.id);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  }, []);

  const handleSubmit = async () => {
    try {
      if (!id) return;
      const params = {
        feedback: textValue
      };
      setAppLoading(true);
      await updateRatingEmail({ id, params });
      setAppLoading(false);
      navigate('/rating/thanks', { replace: true });
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  return (
    <div className="ratings-page-wrapper-parent">
      <div className="ratings-page-wrapper">
        <div className="ratings-page-wrapper--body">
          <div className="ratings-page-wrapper--header">
            <div className="ratings-page-wrapper--header-logo-wrapper">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="ratings-page-wrapper--body-title">
            <h1>Thanks for your feedback.</h1>
            <h5>If you have additional comments, please share them here:</h5>
          </div>
          <div className="ratings-page-input-wrapper">
            <textarea
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              placeholder="How else can we improve Uthrive?"
              className="ratings-page--text-area"
            />
          </div>
          <button disabled={!textValue} onClick={handleSubmit} className="ratings--btn-submit">
            Submit
          </button>
          <div className="rating-footer">
            © Copyright 2021 Uthrive Inc. All rights reserved.{' '}
            <a href="https://www.uthrive.club/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
