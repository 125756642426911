import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import chevLeft from 'assets/images/chevron-left.svg';
import { useAppStore, useAuthStore } from 'store';
import { CustomButton, ModalCustom, OtpInput } from 'components';
import { sendOtpDeleteAccount, submitOtpDeleteAccount } from 'services';
import { toast } from 'react-toastify';
import './style.scss';
import { Auth } from 'aws-amplify';

export const ProfileDelete = () => {
  const navigate = useNavigate();

  const { user, doLogout } = useAuthStore();
  const { setAppLoading } = useAppStore();

  const [count, setCount] = useState(0);
  const [value, setValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  const sendOtp = async () => {
    try {
      setAppLoading(true);
      await sendOtpDeleteAccount();
      setOpenModal(true);
      setAppLoading(false);
    } catch (error) {
      setAppLoading(false);
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value);
    setCount(e.target.value?.trim()?.length);
  };

  const handleChangeOtp = async (otp: string) => {
    setOtpValue(otp);
    if (otp?.length === 6) {
      try {
        setAppLoading(true);
        await submitOtpDeleteAccount({
          OTP: otp,
          feedback: value
        });
        setOpenModal(false);
        setOpenSuccessModal(true);
        setTimeout(async () => {
          handleLogout();
        }, 2000);
        setAppLoading(false);
      } catch (err: any) {
        setAppLoading(false);
        toast.error('Invalid OTP');
        console.log(err);
      }
    }
  };

  const handleLogout = async () => {
    try {
      setAppLoading(true);
      await Auth.signOut();
      doLogout();
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const resendOtp = async () => {
    try {
      if (seconds === 0) {
        setAppLoading(true);
        setSeconds(60);
        await sendOtp();
        toast.success('A new code sent to you');
        setAppLoading(false);
      } else {
        toast.error(`Too quick! Please wait for ${seconds} seconds before clicking ‘Resend’`);
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <div className="profile">
      <div className="profile-container">
        <div className="profile-header">
          <button className="profile-btnBack" onClick={handleBackBtn}>
            <img src={chevLeft} alt="chevron-left" />
            Back
          </button>
          <h1>Delete Account</h1>
        </div>
        <div className="profile-body">
          <p className="feedback-desc">
            We are sorry to see you go!
            <br />
            <br />
            Please share the reason you would like to delete your account
          </p>

          <FormGroup>
            <Label className="feedback-label">Feedback</Label>
            <Input
              className="feedback-textarea"
              id="exampleText"
              name="text"
              type="textarea"
              onChange={handleChangeValue}
              maxLength={4000}
              value={value}
            />
            <p className={`feedback-count ${count === 4000 ? 'maxed' : ''}`}>{count}/4000</p>
          </FormGroup>
          <FormGroup check inline>
            <Input
              className="feedback-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <Label className="feedback-checkbox-label" check>
              Your account includes personal data you have shared with Uthrive. Once your account is deleted, we
              won&apos;t be able to get it back for you. I understand what will happen after my data is deleted, I want
              to continue with this request.
            </Label>
          </FormGroup>

          <div className="feedback-submitBox">
            <CustomButton type="submit" onClick={sendOtp} disabled={!isChecked || !value?.trim()}>
              Continue
            </CustomButton>
          </div>
        </div>
      </div>
      <ModalCustom
        className="modal-lg modal-signup-account feedback-modal"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <h2>
          We sent you a 6-digit code to your email {user?.email_address} and phone (if provided), please verify the code
        </h2>
        <div className="screen-code">
          <div className="otp-wrapper">
            <div>
              <OtpInput value={otpValue} handleChage={handleChangeOtp} />
              <div className="otp-resend">
                Didn’t get the code?{' '}
                <span className="text-link" onClick={resendOtp}>
                  Resend.
                </span>
              </div>
            </div>
          </div>
        </div>
      </ModalCustom>
      <ModalCustom
        className="modal-lg modal-signup-account feedback-modal"
        isOpen={openSuccessModal}
        toggle={() => setOpenModal(!openSuccessModal)}
        onClose={() => setOpenModal(!openSuccessModal)}>
        <h2>Your request has been submitted, please check your email for confirmation.</h2>
        <p>
          Your request may take up to 30 days. During this time you may not be able to access Uthrive, nor can you
          submit another request.
        </p>
      </ModalCustom>
    </div>
  );
};
