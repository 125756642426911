import { Table } from 'reactstrap';
import './style.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'store';
import { getPayoutHistories, getPayoutHistoryDetail } from 'services';
import { PayoutHistoryDetailType, PayoutHistoryType } from 'types';
import moment from 'moment';
import { fixMoney } from 'utils';
import { ModalPayoutHistory } from 'components';
import chevLeft from 'assets/images/chevron-left.svg';

const limit = 10;

export const RedeemHistory = () => {
  const navigate = useNavigate();

  const { setAppLoading } = useAppStore();

  const [payoutHistory, setPayoutHistory] = useState<PayoutHistoryType[]>([]);
  const [historyDetail, setHistoryDetail] = useState<PayoutHistoryDetailType | null>(null);

  useEffect(() => {
    fetchDate();
  }, []);

  const fetchDate = async () => {
    try {
      setAppLoading(true);
      const params = { offset: 0, limit };
      const resp = await getPayoutHistories(params);
      setPayoutHistory(resp?.data || []);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleViewDetail = async (value: PayoutHistoryType) => {
    try {
      setAppLoading(true);
      const resp = await getPayoutHistoryDetail(value.commissionPayoutId);
      setHistoryDetail(resp.data || null);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleBackBtn = () => {
    navigate('/offers/my-savings', { replace: true });
  };

  return (
    <div className="redeem-history">
      <button className="redeem-history-btnBack" onClick={handleBackBtn}>
        <img src={chevLeft} alt="chevron-left" />
        Back
      </button>
      <div className="redeem-history-title">Your Redeem History</div>
      <div className="redeem-history-table-container">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Ref#</th>
            </tr>
          </thead>
          <tbody>
            {payoutHistory?.map((h, idx) => {
              return (
                <tr key={idx}>
                  <td>{moment(h.transactionDate).format('DD/MM/yyyy')}</td>
                  <td>${fixMoney(h.transactionAmount)}</td>
                  <td>{h.transactionStatus}</td>
                  <td>
                    <span className="redeem-history-table-action" onClick={() => handleViewDetail(h)}>
                      View
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <ModalPayoutHistory isOpen={!!historyDetail} onClose={() => setHistoryDetail(null)} data={historyDetail} />
    </div>
  );
};
