import { useAppStore } from 'store/appStore';
// import moment from 'moment';
import { CustomButton, ModalCustom, OtpInput } from 'components';
import { useEffect, useState } from 'react';
import { confirmCancelSubscription, requestCancelSubscription } from 'services/premium';
import { Input } from 'reactstrap';
import { toast } from 'react-toastify';

export const PremiumMemberSection = ({ callback }: { callback: () => void }) => {
  const { activeSubscription, setAppLoading } = useAppStore();
  const [otp, setOtp] = useState('');
  // const [invoices, setInvoices] = useState<any[]>([]);
  const [showModalConfirmCancel, setShowModalConfirmCancel] = useState(false);
  const [showModalOptCancelSub, setShowModalOptCancelSub] = useState(false);
  const [reason, setReason] = useState('');
  // const onClickCancelSubscription = () => {
  //   setShowModalConfirmCancel(true);
  // };

  const onCloseModal = () => {
    setShowModalConfirmCancel(!showModalConfirmCancel);
  };

  const onCloseModalOpt = () => {
    setShowModalOptCancelSub(!showModalOptCancelSub);
  };

  const handleRequestCancelSubcription = async () => {
    setAppLoading(true);
    setShowModalConfirmCancel(false);
    const res = await requestCancelSubscription();
    setAppLoading(false);
    console.log({ res });
    if (res.success) {
      setShowModalOptCancelSub(true);
    } else {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const submitCancelSub = async () => {
    if (!otp || otp.length !== 6) {
      toast.error('Please input your otp.');
      return;
    }
    try {
      setAppLoading(true);
      await confirmCancelSubscription({
        subscriptionId: activeSubscription?.id || '',
        otp,
        cancelReason: reason
      });
      setAppLoading(false);
      toast.success('Cancelled premium successfully');
      setShowModalOptCancelSub(false);
      callback();
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong. Please try again later.');
    }
  };

  useEffect(() => {
    if (!showModalOptCancelSub) {
      setOtp('');
      setReason('');
    }
  }, [showModalOptCancelSub]);
  console.log({ activeSubscription });

  // const getInvoicesSub = async (id: string) => {
  //   const res = await getInvoices({ userSubscriptionId: id });
  //   setInvoices(res);
  // };
  // useEffect(() => {
  //   if (activeSubscription?.id) {
  //     getInvoicesSub(activeSubscription.id);
  //   }
  // }, [activeSubscription?.id]);
  return (
    <div>
      <ModalCustom
        isOpen={showModalOptCancelSub}
        className="modal-blur modal-lg modal-login-account"
        toggle={onCloseModalOpt}
        onClose={onCloseModalOpt}
        isSupportClose>
        <div className="subscribe-form">
          <div>Please input the OTP and reason</div>
          <div className="otp-wrapper" style={{ marginBottom: 20 }}>
            <div>
              <OtpInput handleChage={(value) => setOtp(value)} value={otp} numInputs={6} />
            </div>
          </div>
          <Input
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            placeholder="Reason for canceling"
          />
          <div style={{ marginTop: 20 }}>
            <CustomButton onClick={() => submitCancelSub()}>Confirm</CustomButton>
            <CustomButton className="cancel-button" onClick={() => setShowModalOptCancelSub(false)}>
              Cancel
            </CustomButton>
          </div>
        </div>
      </ModalCustom>
      <ModalCustom
        isOpen={showModalConfirmCancel}
        className="modal-blur modal-lg modal-login-account"
        toggle={onCloseModal}
        onClose={onCloseModal}
        isSupportClose>
        <div className="subscribe-form">
          <div>Are sure you that you want to cancel the subscription?</div>
          <div style={{ marginTop: 20 }}>
            <CustomButton onClick={() => handleRequestCancelSubcription()}>Confirm</CustomButton>
            <CustomButton className="cancel-button" onClick={() => setShowModalConfirmCancel(false)}>
              Cancel
            </CustomButton>
          </div>
        </div>
      </ModalCustom>
      {/* <div style={{ fontSize: 24, fontWeight: 'bold' }}>You are premium member</div>
      <div style={{ marginBottom: 20 }}>
        {activeSubscription!.upcomingInvoice?.nextRenewalDate && (
          <div style={{ marginTop: 20 }}>
            Next renewal date:{' '}
            {moment(new Date(activeSubscription!.upcomingInvoice.nextRenewalDate)).format('DD-MM-YYYY')}
          </div>
        )}
        {!activeSubscription!.upcomingInvoice && activeSubscription!.userSubscriptionTrialEnd && (
          <div style={{ marginTop: 20 }}>
            Your trial will be ended at:{' '}
            {moment(new Date(activeSubscription!.userSubscriptionTrialEnd)).format('DD-MM-YYYY')}
          </div>
        )}
      </div>
      {activeSubscription!.upcomingInvoice && (
        <CustomButton onClick={onClickCancelSubscription}>Cancel Subscription</CustomButton>
      )} */}
    </div>
  );
};
