import { SpentTotalParamsType, UserSurveyParamsType } from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

export const getTotalSpending = async (params?: SpentTotalParamsType) => {
  const resp = await axiosClient.get(endpoints.totalSpending, { params });
  return resp.data;
};

export const getAvailableRewards = async () => {
  const resp = await axiosClient.get(endpoints.availableRewards);
  return resp.data;
};

export const getRewardsSummary = async () => {
  const resp = await axiosClient.get(endpoints.rewardSummary);
  return resp.data;
};

export const getLatestOffers = async () => {
  const resp = await axiosClient.get(endpoints.latestOffers);
  return resp.data;
};

export const contactUs = async (params: { caseDescription: string }) => {
  const resp = await axiosClient.post(endpoints.contactUs, params);
  return resp.data;
};

export const contactAddMoreMerchant = async (params: { caseType: string; caseDescription: string }) => {
  const resp = await axiosClient.post(endpoints.contactUs, params);
  return resp.data;
};

export const getUserSurvey = async () => {
  const resp = await axiosClient.get(endpoints.userSurvey);
  return resp.data;
};

export const submitUserSurvey = async (params: UserSurveyParamsType) => {
  const resp = await axiosClient.post(endpoints.userSurveySave, params);
  return resp.data;
};
