import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { fixMoney } from 'utils';
import { CustomButton } from 'components/Button';

type props = {
  isOpen: boolean;
  onClose: () => void;
  total: number;
  handleContinue: () => void;
};

export const ModalRedeemSavings = ({ total = 0, isOpen = false, onClose, handleContinue }: props) => {
  return (
    <ModalCustom
      isOpen={isOpen}
      className="custom-modal-redeem-savings"
      toggle={onClose}
      onClose={onClose}
      isSupportClose>
      <div className="modal-redeem-savings-container">
        <div className="modal-redeem-savings-title">Redeem Savings</div>
        <div className="modal-redeem-savings-subtitle">
          You are about to redeem <span>${fixMoney(total)}</span> from you savings.
        </div>
        <div className="modal-redeem-savings-desc">
          Uthrive delivers your savings as payout via PayPal into your linked checking or savings account.
          <br />
          <br />
          After initiating payment successfully, most payouts are completed in 3-5 business days unless more time is
          needed for verification by PayPal or your bank.
          <br />
          <br />
          If you don&apos;t have an active PayPal account, you will be able to create it on the next screen.
        </div>
        <CustomButton onClick={handleContinue}>Continue</CustomButton>
      </div>
    </ModalCustom>
  );
};
