import { ModalCustom } from 'components/ModalCustom';
import logo from 'assets/images/logo.svg';
import './style.scss';

type props = {
  onClose: () => void;
  isOpen: boolean;
  handleClickSignin: () => void;
  handleClickSignup: () => void;
  socialName: string;
};

export const ModalNotFoundAccount = ({ onClose, isOpen, handleClickSignin, handleClickSignup, socialName }: props) => {
  return (
    <ModalCustom
      onClose={onClose}
      toggle={onClose}
      isOpen={isOpen}
      className="modal-blur modal-lg modal-authen-methods">
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div className="modal-not-found-account">
        <div className="modal-not-found-account--title">No Account Found.</div>
        <div className="modal-not-found-account--text">
          We didn’t find any account associated with Email on your {socialName} login. Please sign in another way or
          Sign up.
        </div>
        <div className="modal-not-found-account--btns">
          <button className="modal-not-found-account--btn" onClick={handleClickSignin}>
            Sign in
          </button>
          <button className="modal-not-found-account--btn" onClick={handleClickSignup}>
            Sign up
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};
