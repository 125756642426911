import { CardsStoreType } from 'types';
import { create } from 'zustand';

const initState = {
  featuredCards: []
};

export const useCardsStore = create<CardsStoreType>((set) => ({
  featuredCards: [],
  setFeaturedCards: (value) =>
    set(() => {
      return { featuredCards: value };
    }),
  reset: () => set({ ...initState })
}));
