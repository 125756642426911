import Select, { SingleValue } from 'react-select';
import { formatMoney } from 'utils';
import { ACTIVE_STATUS, activatedOffersOptions } from 'const';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppStore } from 'store';
import { useNavigate } from 'react-router-dom';
import {
  favoriteBankOffer,
  favoriteStoreOffer,
  getBankOfferCategories,
  getBanks,
  getListBankOffers,
  getListStoreOffers,
  getMySubscriptions,
  getStoreOfferCategories,
  removeFavoriteStoreOffer
} from 'services';
import { toast } from 'react-toastify';
import bankIcon from 'assets/images/gray-icon-bank.png';
import storeIcon from 'assets/images/gray-icon-store.png';
import preIcon from 'assets/images/left-arrow.png';
import nextIcon from 'assets/images/right-arrow.png';
import bankBlueIcon from 'assets/images/blue-icon-bank.png';
import storeBlueIcon from 'assets/images/blue-icon-store.png';
import { ActivatedBankOfferType, ActivatedMerchantOfferType } from 'types/premium';
import { ActivatedOffer, ModalActivatedOfferDetails, PopoverDownloadApp } from 'components';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import searchIcon from 'assets/images/search-icon.png';
import iconClose from 'assets/images/nav-toggle-close.png';
import { Input } from 'reactstrap';
import { debounce } from 'lodash';

type OfferCategory = {
  value: string;
  label: string;
  isBank: boolean;
};

const categoryAll = { value: 'all', label: 'All', isBank: false };

const page_size = 10;

export const ActivatedOffers = () => {
  const navigate = useNavigate();

  const { setAppLoading } = useAppStore();
  const [filter, setFilter] = useState<{ value: string; label: string } | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [bankOfferCategories, setBankOfferCategories] = useState<OfferCategory[]>([]);
  const [storeOfferCategories, setStoreOfferCategories] = useState<OfferCategory[]>([]);
  const [bankCategory, setBankCategory] = useState<OfferCategory>(categoryAll);
  const [storeCategory, setStoreCategory] = useState<OfferCategory>(categoryAll);
  const [skipBank, setSkipBank] = useState(0);
  const [skipStore, setSkipStore] = useState(0);
  const [bankOffers, setBankOffers] = useState<ActivatedBankOfferType[]>([]);
  const [storeOffers, setStoreOffers] = useState<ActivatedMerchantOfferType[]>([]);
  const [amountBankOffers, setAmountBankOffers] = useState(0);
  const [amountStoreOffers, setAmountStoreOffers] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState<ActivatedBankOfferType | ActivatedMerchantOfferType | null>(null);
  const [isOpenPopoverActivateOffer, setIsOpenPopoverActivateOffer] = useState(false);
  const [isOpenPopoverManagePremium, setIsOpenPopoverManagePremium] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchStoreOffer, setSearchStoreOffer] = useState('');
  const [searchBankOffer, setSearchBankOffer] = useState('');
  const [searchTermStoreOffer, setSearchTermStoreOffer] = useState('');
  const [searchTermBankOffer, setSearchTermBankOffer] = useState('');

  const categoriesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    if (bankOfferCategories?.length === 0) {
      return;
    } else {
      fetchListBankOffers();
    }
  }, [filter, bankCategory, bankOfferCategories, searchTermBankOffer]);

  useEffect(() => {
    if (storeOfferCategories?.length === 0) {
      return;
    } else {
      getListOffers();
    }
  }, [filter, storeCategory, storeOfferCategories, searchTermStoreOffer]);

  const getSubscriptions = async () => {
    try {
      setAppLoading(true);
      const resp = await getMySubscriptions();
      const activeSub = resp.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1);

      if (!activeSub) {
        setAppLoading(false);
        toast.error('Subscribe Uthrive premium to see your offers');
        navigate('/premium', { replace: true });
        return;
      }

      const respData = await Promise.all([getBankOfferCategories(), getStoreOfferCategories(), getBanks()]);

      if (respData && respData[0]) {
        const banks =
          respData[2]?.data?.map((b: any) => {
            return { value: b.institution_short_name, label: b.institution_short_name, isBank: true };
          }) || [];
        const temp = respData[0]?.map((c: string) => {
          return { value: c, label: c, isBank: false };
        });
        setBankOfferCategories([{ value: 'all', label: 'All', isBank: false }, ...temp, ...banks]);
      }

      if (respData && respData[1]) {
        const temp = respData[1]?.map((c: string) => {
          return { value: c, label: c, isBank: false };
        });
        setStoreOfferCategories([{ value: 'all', label: 'All' }, ...temp]);
      }

      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const fetchListBankOffers = async () => {
    try {
      const params = {
        offerCategory: searchTermBankOffer ? 'all' : bankCategory.value,
        filterType: filter?.value,
        bankName: '',
        search: searchTermBankOffer || undefined
      };

      if (bankCategory?.isBank) {
        params.offerCategory = '';
        params.bankName = bankCategory.value;
      }

      setSkipBank(0);
      setAppLoading(true);
      const resp = await getListBankOffers(params);

      setBankOffers(resp?.data || []);
      setAmountBankOffers(resp?.totalValue || 0);

      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const getListOffers = async () => {
    try {
      const paramsStore = {
        storeName: searchTermStoreOffer ? 'all' : storeCategory.value,
        filterType: filter?.value,
        search: searchTermStoreOffer || undefined
      };
      setSkipStore(0);
      setAppLoading(true);
      const resp = await getListStoreOffers(paramsStore);

      setStoreOffers(resp?.data || []);
      setAmountStoreOffers(resp?.totalValue || 0);

      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleSelect = (option: SingleValue<{ value: string; label: string }>) => {
    setFilter(option);
    setSkipBank(0);
    setSkipStore(0);
    setBankOffers([]);
    setStoreOffers([]);
  };

  const handleChangeCategory = (value: OfferCategory) => {
    if (activeTab === 0) {
      setBankCategory(value);
      setSkipBank(0);
      setBankOffers([]);
    } else {
      setStoreCategory(value);
      setSkipStore(0);
      setStoreOffers([]);
    }
  };

  const handleClickOffer = (data: ActivatedBankOfferType | ActivatedMerchantOfferType) => {
    setSelectedOffer(data);
  };

  const handleLoadMore = () => {
    if (activeTab === 0) {
      setSkipBank(skipBank + 1);
    } else {
      setSkipStore(skipStore + 1);
    }
  };

  const handleFavoriteOffer = async (data: ActivatedBankOfferType | ActivatedMerchantOfferType, cb: () => void) => {
    try {
      setAppLoading(true);
      if (activeTab === 0) {
        await favoriteBankOffer(data?.id);
        const idx = bankOffers?.findIndex((bo) => bo?.id === data.id);
        if (idx !== -1) {
          const temp = [...bankOffers];
          temp[idx].isFav = !temp[idx].isFav;
          setBankOffers([...temp]);
        }
      } else {
        let realService = favoriteStoreOffer;
        if (data?.isFav) {
          realService = removeFavoriteStoreOffer;
        }
        await realService(data?.id);
        const idx = storeOffers?.findIndex((so) => so?.id === data.id);
        if (idx !== -1) {
          const temp = [...storeOffers];
          temp[idx].isFav = !temp[idx].isFav;
          setStoreOffers([...temp]);
        }
      }
      setAppLoading(false);
      if (cb) {
        cb();
      }
    } catch (err) {
      console.log(err);
      setAppLoading(false);
      toast.error('Something went wrong');
    }
  };

  const handlePreScroll = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollTo({
        left: categoriesRef.current.scrollLeft - 200,
        behavior: 'smooth'
      });
    }
  };

  const handleNextScroll = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollTo({
        left: categoriesRef.current.scrollLeft + 200,
        behavior: 'smooth'
      });
    }
  };

  const renderedCategories = useMemo(() => {
    if (activeTab === 0) return bankOfferCategories;
    return storeOfferCategories;
  }, [activeTab, bankOfferCategories, storeOfferCategories]);

  const renderStoreOffers = useMemo(() => {
    if (storeOffers.length === 0) {
      return [];
    }
    if ((skipStore + 1) * page_size > storeOffers.length) {
      return [...storeOffers];
    }
    const temp = [...storeOffers];
    const startIdx = 0;
    const endIdx = (skipStore + 1) * page_size;
    const tempStore = temp?.slice(startIdx, endIdx);
    return tempStore;
  }, [storeOffers, skipStore]);

  const renderBankOffers = useMemo(() => {
    if (bankOffers.length === 0) {
      return [];
    }
    if ((skipBank + 1) * page_size > bankOffers.length) {
      return [...bankOffers];
    }
    const temp = [...bankOffers];
    const startIdx = 0;
    const endIdx = (skipBank + 1) * page_size;
    const tempBank = temp?.slice(startIdx, endIdx);
    return tempBank;
  }, [skipBank, bankOffers]);

  const handleValueSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (activeTab === 0) {
      setSearchBankOffer(val);
    } else {
      setSearchStoreOffer(val);
    }
    handleChangeSearchValue(val);
  };

  const handleChangeSearchValue = useCallback(
    debounce(async (val: string) => {
      if (activeTab === 0) {
        setSearchTermBankOffer(val);
      } else {
        setSearchTermStoreOffer(val);
      }
    }, 400),
    [activeTab]
  );

  const handleClearnSearch = () => {
    setSearchBankOffer('');
    setSearchStoreOffer('');
    setSearchTermBankOffer('');
    setSearchTermStoreOffer('');
  };

  const handleClickSearchBtn = () => {
    if (activeSearch) {
      handleClearnSearch();
    }
    setActiveSearch(!activeSearch);
  };

  const handleBackBtn = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  const renderedOffers = useMemo(() => {
    if (activeTab === 0) {
      return renderBankOffers;
    }

    return renderStoreOffers;
  }, [activeTab, renderBankOffers, renderStoreOffers]);

  const renderedAmount = useMemo(() => {
    return activeTab === 0 ? amountBankOffers : amountStoreOffers;
  }, [activeTab, amountBankOffers, amountStoreOffers]);

  const showBtnLoadMore = useMemo(() => {
    if (activeTab === 0 && renderBankOffers?.length !== 0) {
      if (renderBankOffers?.length === bankOffers.length) {
        return false;
      }
      return true;
    } else if (activeTab === 1 && renderStoreOffers?.length !== 0) {
      if (renderStoreOffers.length === storeOffers.length) {
        return false;
      }
      return true;
    }
    return false;
  }, [activeTab, renderBankOffers, renderStoreOffers, bankOffers, storeOffers]);

  return (
    <div className="activated-offers-container">
      <div className="activated-offers--top-btns">
        <button
          className="activated-offers--top-btn"
          id="activate-more-offers"
          onClick={() => setIsOpenPopoverActivateOffer(true)}>
          Activate more offers
        </button>
        <button
          className="activated-offers--top-btn"
          id="manage-premium"
          onClick={() => setIsOpenPopoverManagePremium(true)}>
          Manage Premium
        </button>
      </div>
      <button className="my-savings-btnBack" onClick={handleBackBtn}>
        <img src={chevLeft} alt="chevron-left" />
        Back
      </button>
      <div className="activated-offers-header">
        <div className="activated-offers-header--col1">
          <div className="activated-offers-header--title">Your offers worth:</div>
          <div className="activated-offers-header--amount">${formatMoney(renderedAmount)}</div>
        </div>
        <div className="activated-offers-header--col2">
          <Select
            isClearable
            className="activated-offers-select-container"
            classNamePrefix="activated-offers-select"
            placeholder="Filter"
            options={activatedOffersOptions}
            value={filter}
            onChange={(option) => handleSelect(option)}
          />
          <button className={`search-btn ${activeSearch ? 'active' : ''}`} onClick={handleClickSearchBtn}>
            <img src={searchIcon} alt="icon" className="search-icon" />
          </button>
        </div>
      </div>
      <div className="activated-offers-body">
        <div className="activated-offers-tabs">
          <div className={`activated-offers-tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
            <img className="activated-offers-tab--img" src={activeTab === 0 ? bankBlueIcon : bankIcon} alt="bank" />
            <div className="activated-offers-tab--title">Bank</div>
          </div>
          <div className={`activated-offers-tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
            <img className="activated-offers-tab--img" src={activeTab === 1 ? storeBlueIcon : storeIcon} alt="store" />
            <div className="activated-offers-tab--title">Store</div>
          </div>
        </div>
        {!activeSearch ? (
          <div className="activated-offers-categories-container">
            <div className="activated-offers-categories" ref={categoriesRef}>
              {renderedCategories?.map((ct, idx) => {
                return (
                  <div
                    className={`activated-offers-category ${activeTab === 0 && ct.value === bankCategory.value ? 'selected' : activeTab === 1 && ct.value === storeCategory.value ? 'selected' : ''}`}
                    key={ct.value}
                    onClick={() => handleChangeCategory(ct)}>
                    {ct.label}
                  </div>
                );
              })}
            </div>
            <div className="activated-offers-categories--action">
              <img
                src={preIcon}
                alt="pre"
                className="activated-offers-categories--pre-icon"
                onClick={handlePreScroll}
              />
              <img
                src={nextIcon}
                alt="next"
                className="activated-offers-categories--next-icon"
                onClick={handleNextScroll}
              />
            </div>
          </div>
        ) : (
          <div className="activated-offers-search-container">
            <img src={searchIcon} alt="search" />
            <Input
              value={activeTab === 0 ? searchBankOffer : searchStoreOffer}
              onChange={handleValueSearchChange}
              placeholder="Search offer"
            />
            {((activeTab === 0 && !!searchBankOffer) || (activeTab === 1 && !!searchStoreOffer)) && (
              <img src={iconClose} alt="close" onClick={handleClearnSearch} className="clearn-search-icon" />
            )}
          </div>
        )}
        <div className="activated-offers-list">
          {renderedOffers?.map((offer, idx) => {
            return (
              <ActivatedOffer
                data={offer}
                key={offer.id}
                handleClickOffer={handleClickOffer}
                handleFavOffer={handleFavoriteOffer}
              />
            );
          })}
        </div>
        <div className="activated-offers-load-more">
          {showBtnLoadMore && (
            <div className="activated-offers-load-more-btn" onClick={handleLoadMore}>
              Load More
            </div>
          )}
        </div>
      </div>
      <ModalActivatedOfferDetails
        isOpen={!!selectedOffer}
        onClose={() => setSelectedOffer(null)}
        data={selectedOffer}
      />
      <PopoverDownloadApp
        title="Activate more offers in the Uthrive App"
        isOpen={isOpenPopoverActivateOffer}
        handleDone={() => setIsOpenPopoverActivateOffer(false)}
        target="activate-more-offers"
        placement="bottom"
        modalSize="bg"
      />
      <PopoverDownloadApp
        title="Manage Premium in the Uthrive App"
        isOpen={isOpenPopoverManagePremium}
        handleDone={() => setIsOpenPopoverManagePremium(false)}
        target="manage-premium"
        placement="bottom"
        modalSize="bg"
      />
    </div>
  );
};
