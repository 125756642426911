import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthStore } from 'store';

type Props = {
  redirectPath: string;
};

export const ProtectedRoute = ({ redirectPath }: Props) => {
  const location = useLocation();

  const { token, setBrowserLocation, browserLocation } = useAuthStore();

  useEffect(() => {
    if (!token && (browserLocation?.pathname === '' || browserLocation?.pathname === '/')) {
      setBrowserLocation(location);
    }
  }, [location, token]);

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};
