import moment from 'moment';
import { ActivatedBankOfferType, ActivatedMerchantOfferType } from 'types/premium';
import iconStar from 'assets/images/star.png';
import iconStarFilled from 'assets/images/star-filled.png';
import { useState } from 'react';
import './style.scss';
import { toast } from 'react-toastify';

type props = {
  data: ActivatedBankOfferType | ActivatedMerchantOfferType;
  handleClickOffer: (data: ActivatedBankOfferType | ActivatedMerchantOfferType) => void;
  handleFavOffer: (data: ActivatedBankOfferType | ActivatedMerchantOfferType, cb: () => void) => void;
};

export const ActivatedOffer = ({ data, handleClickOffer, handleFavOffer }: props) => {
  const [isFav, setIsFav] = useState(data?.isFav || false);

  const cardUrl = 'mappedCard' in data ? data?.mappedCard?.image?.url : '';
  const cardInsUrl = 'institution' in data ? data?.institution.image.url : '';
  const cardName = 'institution' in data ? data?.institution?.institutionShortName + ' - ' + data?.cardMask : '';
  const offerUrl = data?.offerLogo;
  const storeUrl = 'storeLogo' in data ? data?.storeLogo : '';
  const offerName = 'offerMerchant' in data && data?.offerMerchant ? data?.offerMerchant : data?.offerName;
  const offerLang = 'offerShortDescription' in data ? data?.offerShortDescription : data?.earnOfferLang;
  const handleFav = () => {
    handleFavOffer(data, () => {
      setIsFav(!isFav);
      if (!isFav) {
        toast.success('Offer set as favorite');
      } else {
        toast.success('Offer unfavorited');
      }
    });
  };

  return (
    <div className="activated-offer-item-container">
      <div className="activated-offer-item-col1">
        <img
          className="activated-offer-item-icon-star"
          src={isFav ? iconStarFilled : iconStar}
          alt="star"
          onClick={handleFav}
        />
        <div className="activated-offer-item-info-container">
          <img className="activated-offer-item-info-offer-logo" src={offerUrl} alt="merchant" />
          <div className="activated-offer-item-info">
            <div className="activated-offer-item-info--name">{offerName}</div>
            <div className="activated-offer-item-info--lang">{offerLang}</div>
            <div className="activated-offer-item-info--date">
              Valid: {moment(data?.offerExpiryDate)?.format('ddd, MM/DD')}
            </div>
            <div className="activated-offer-item-info--view" onClick={() => handleClickOffer(data)}>
              View details
            </div>
          </div>
        </div>
      </div>
      <div className="activated-offer-item-col2">
        {cardName ? (
          <div className="activated-offer-item-card-container">
            <img
              className={`image ${cardUrl ? 'cardImg' : 'insImg'}`}
              src={cardUrl ? cardUrl : cardInsUrl}
              alt="card"
            />
            <div>{cardName}</div>
          </div>
        ) : (
          <img className="activated-offer-item-store-logo" src={storeUrl} alt="store" />
        )}
      </div>
    </div>
  );
};
