import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
type props = {
  title: string;
  desc: string;
  img: string;
  firstItem: boolean;
  isDisplay?: boolean;
  showAll?: () => void;
};

export const PremiumBenefitItem = ({ title, desc, img, firstItem, showAll, isDisplay }: props) => {
  return (
    <>
      <div className={`premium-benefit-item-container`}>
        <img src={img} alt="logo" className="premium-benefit-img" />
        <div className="premium-benefit-info-container">
          <div className="title">{title}</div>
          <div className="desc">{desc}</div>
        </div>
      </div>
      {firstItem && (
        <button className={`premium-show-all ${isDisplay && 'show'}`} onClick={showAll}>
          And other exciting benefits <img src={chevLeft} alt="show" />
        </button>
      )}
    </>
  );
};
