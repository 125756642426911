import './style.scss';
import { EarnedTransactionType } from 'types';
import moment from 'moment';
import { fixMoney } from 'utils';
import { useNavigate } from 'react-router-dom';

type props = {
  data: EarnedTransactionType;
  cardId: string;
};

export const TransactionEarnedReward = ({ data, cardId }: props) => {
  const navigate = useNavigate();
  return (
    <div className="transaction-item">
      <div className="transaction-infobox">
        <div className="info">
          <span className="info-name">{data?.name}</span>
          <span className="info-date">Date: {moment(data?.date)?.format('ddd, MM/DD/YYYY')}</span>
          <span className="info-spent">Spent: ${fixMoney(data?.spentAmount)}</span>
        </div>
        <div className="reward">
          <span>${fixMoney(data?.rewardAmount)}</span>
        </div>
      </div>
      <span
        className="transaction-report"
        onClick={() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
          navigate(
            `/rewards-earned/report-issue/${cardId}/${data?.id}/?isReported=${data.isSubmittedWrongCategorizationReport || false}`
          );
        }}>
        {data?.isSubmittedWrongCategorizationReport ? 'Issue Reported!' : 'Report Issues'}
      </span>
    </div>
  );
};
