import { ModalCustom } from 'components/ModalCustom';
import { CustomButton } from 'components/Button';
import { useNavigate } from 'react-router-dom';
import './style.scss';

import { RedeemSocketPayload } from 'types';
import clock from 'assets/images/clock.png';
import warning from 'assets/images/warning.png';
import checkIcon from 'assets/images/tick.png';
import { useMemo } from 'react';

type props = {
  isOpen: boolean;
  onClose: () => void;
  data: RedeemSocketPayload | null;
};

const contents = {
  success: {
    text1: 'Your payout is all set!',
    text2: 'You should be receiving it in 3-5 business days, depending on your bank.',
    icon: checkIcon
  },
  pending: {
    text1: 'Your payout is in progress.',
    text2:
      'Look out for an email from PayPal on the next steps in next 24 hours. If no email received - report an issue.',
    icon: clock
  },
  failed: {
    text1: 'We are having trouble in processing this payout.',
    text2: 'Please try again, if this situation persits - please report an issue.',
    icon: warning
  }
};

export const ModalPayoutTimeout = ({ isOpen = false, onClose, data }: props) => {
  const navigate = useNavigate();

  const handleMySavings = () => {
    onClose();
  };
  const handleHistory = () => {
    onClose();
    navigate('/offers/redeem-history');
  };

  const redeemStatus = useMemo(() => {
    if (!data) return 'pending';
    switch (data?.transactionStatus) {
      case 'SUCCESS':
        return 'success';
      case 'UNCLAIMED':
      case 'ONHOLD':
        return 'pending';
      case 'FAILED':
        return 'failed';
      default:
        return 'pending';
    }
  }, [data]);

  const renderData = contents[redeemStatus];

  return (
    <ModalCustom
      isOpen={isOpen}
      className="custom-modal-redeem-savings"
      toggle={onClose}
      onClose={onClose}
      isSupportClose>
      <div className="modal-redeem-savings-container">
        <div className="modal-redeem-savings-title justify-content-center">Redeem Savings</div>
        <div className="clock-container">
          <img src={renderData.icon} alt="clock" />
        </div>
        <div className="modal-redeem-savings-desc">
          {renderData.text1}
          <br />
          <br />
          {renderData.text2}
        </div>
        <CustomButton className="btn-redeem-history" onClick={handleHistory}>
          Redeem History
        </CustomButton>
        <CustomButton className="btn-my-savings" onClick={handleMySavings}>
          My Savings
        </CustomButton>
      </div>
    </ModalCustom>
  );
};
