import Select, { SingleValue } from 'react-select';
import { fixMoney } from 'utils';
import { savingStatusOptions } from 'const';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppStore, useAuthStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { getCategories, getOffersMerchantSavings, getTotalCommissions } from 'services';
import './style.scss';
import { RedeemSocketPayload, SavingOfferType, categoryRecordType } from 'types';
import threedots from 'assets/images/three-dots.png';
import threedotsWhite from 'assets/images/three-dots-white.png';
import { isArray } from 'lodash';
import { useOutsideClick } from 'hooks';
import {
  ModalPayoutTimeout,
  ModalRedeemSavings,
  ModalRedeemSubmit,
  ModalSavingOfferDetail,
  PopoverDownloadApp,
  SavingOffer
} from 'components';
import chevLeft from 'assets/images/chevron-left.svg';
import preIcon from 'assets/images/left-arrow.png';
import nextIcon from 'assets/images/right-arrow.png';
const limit = 10;

export const MySavingsOffers = () => {
  const navigate = useNavigate();

  const { user } = useAuthStore();
  const { setAppLoading, categoriesMySavings, setCategoriesMySavings, appLoading } = useAppStore();

  const [filter, setFilter] = useState<{ value: string; label: string } | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<categoryRecordType | null>(null);
  const [savingsOffers, setSavingsOffers] = useState<SavingOfferType[]>([]);
  const [amountOffers, setAmountOffers] = useState(0);
  const [offset, setOffset] = useState(0);
  const [expandFilter, setExpandFilter] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);
  const [offerDetail, setOfferDetail] = useState<SavingOfferType | null>(null);
  const [showModalRedeem, setShowModalRedeem] = useState(false);
  const [showModalSubmit, setShowModalSubmit] = useState(false);
  const [showModalTimeout, setShowModalTimeout] = useState(false);
  const [dataSocket, setDataSocket] = useState<RedeemSocketPayload | null>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const categoriesRef = useRef<HTMLDivElement>(null);
  const expandBtnRef = useRef(null);

  const sumRedeemAmount = useMemo(() => {
    return savingsOffers?.reduce((acc, curr) => {
      if (selectedOffers.includes(curr.userOfferCommissionDetailId)) {
        return acc + curr.endUserCommissionAmount;
      }
      return acc;
    }, 0);
  }, [selectedOffers, savingsOffers]);

  const handleClickOutSide = () => {
    setExpandFilter(false);
  };

  const expandFilterRef = useOutsideClick<HTMLDivElement>(handleClickOutSide, expandBtnRef);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!categoriesRef.current) return;
    const scrollContainer = categoriesRef.current;
    const tolerance = 2;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

      setIsAtStart(scrollLeft === 0);

      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - tolerance);
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchData = async () => {
    try {
      if (!user?.hasLinkedPlaid && !user?.inactiveUserHistory) {
        window.location.replace('/offers');
        return;
      }
      setAppLoading(true);
      const paramsGetTotal = {
        displayCategory: 'All'
      };
      const paramsOffersSavings = {
        displayCategory: 'All',
        offset,
        limit
      };

      const resps = await Promise.all([
        getCategories(),
        getTotalCommissions(paramsGetTotal),
        getOffersMerchantSavings(paramsOffersSavings)
      ]);

      setSelectedCategory(resps[0][0]);
      setCategoriesMySavings(resps[0] || []);
      setAmountOffers(resps[1]?.data?.totalSaving || 0);
      setSavingsOffers(resps[2]?.data || []);
      if (resps[2]?.data?.length < limit) {
        setHasMore(false);
      }
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const filterData = async (category: string, status?: string, isRefresh?: boolean) => {
    try {
      setAppLoading(true);
      const paramsGetTotal = {
        displayCategory: category,
        status: status
      };
      const paramsOffersSavings = {
        displayCategory: category,
        status: status,
        offset: isRefresh ? 0 : offset,
        limit
      };

      const resps = await Promise.all([
        getTotalCommissions(paramsGetTotal),
        getOffersMerchantSavings(paramsOffersSavings)
      ]);

      setAmountOffers(resps[0]?.data?.totalSaving || 0);
      setSavingsOffers(resps[1]?.data || []);
      if (resps[1]?.data?.length < limit) {
        setHasMore(false);
      }
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchMore = async () => {
    try {
      if (appLoading) {
        return;
      }
      setAppLoading(true);

      const paramsOffersSavings = {
        displayCategory: selectedCategory?.displayCategory,
        status: filter?.value,
        offset: offset + 1,
        limit
      };
      const resp = await getOffersMerchantSavings(paramsOffersSavings);

      const temp = isArray(resp?.data) ? resp?.data : [];

      setSavingsOffers([...savingsOffers, ...temp] || []);
      setOffset(offset + 1);
      if (resp?.data?.length < limit) {
        setHasMore(false);
      }
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleSelect = (option: SingleValue<{ value: string; label: string }>) => {
    setFilter(option);
    setOffset(0);
    filterData(selectedCategory?.displayCategory || 'All', option?.value);
  };

  const handleChangeCategory = (value: categoryRecordType) => {
    setSelectedCategory(value);
    setOffset(0);
    filterData(value?.displayCategory, filter?.value);
  };

  const handleExpandFilter = () => {
    setExpandFilter(!expandFilter);
  };

  const handleClickOffer = (offer: SavingOfferType) => {
    setOfferDetail(offer);
  };

  const handleCheckOffer = (offer: SavingOfferType) => {
    if (offer?.userOfferCommissionStatus !== 'READY') {
      return;
    }
    const id = offer?.userOfferCommissionDetailId;
    if (selectedOffers?.includes(id)) {
      const temp = selectedOffers?.filter((e) => e !== id);
      setSelectedOffers(temp);
      return;
    }
    setSelectedOffers([...selectedOffers, id]);
  };

  const handleContinue = () => {
    setShowModalRedeem(false);
    setShowModalSubmit(true);
  };

  const handleClickRedeemHistory = () => {
    navigate('/offers/redeem-history');
  };

  const handleCloseModal = () => {
    setOfferDetail(null);
  };

  const handleRefreshData = () => {
    setOffset(0);
    setFilter(null);
    setSelectedCategory(categoriesMySavings[0]);
    setSelectedOffers([]);
    filterData('All', undefined, true);
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const handleClickRedeemInApp = () => {
    setIsOpenPopover(!isOpenPopover);
  };

  const handlePreScroll = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollTo({
        left: categoriesRef.current.scrollLeft - 200,
        behavior: 'smooth'
      });
    }
  };

  const handleNextScroll = () => {
    if (categoriesRef.current) {
      categoriesRef.current.scrollTo({
        left: categoriesRef.current.scrollLeft + 200,
        behavior: 'smooth'
      });
    }
  };

  const renderCategoryExpanded = useMemo(() => {
    return categoriesMySavings?.filter((ct) => ct?.displayCategory !== 'All' && ct?.displayCategory !== 'Featured');
  }, [categoriesMySavings]);

  return (
    <div className="my-savings-container">
      <button className="my-savings-btnBack" onClick={handleBackBtn}>
        <img src={chevLeft} alt="chevron-left" />
        Back
      </button>
      <div className="my-savings-title-container">
        <div className="my-savings-title-text">My Savings</div>
        <div className="my-savings-title-btn" onClick={handleClickRedeemHistory}>
          Redeem History
        </div>
      </div>
      <div className="my-savings-header">
        <div className="my-savings-header--col1">
          <div className="my-savings-header--title">Your savings worth:</div>
          <div className="my-savings-header--amount">${fixMoney(amountOffers)}</div>
        </div>
        <div className="my-savings-header--col2">
          <Select
            isClearable
            className="my-savings-select-container"
            classNamePrefix="my-savings-select"
            placeholder="Filter"
            options={savingStatusOptions}
            value={filter}
            onChange={(option) => handleSelect(option)}
          />
        </div>
      </div>
      <div className="my-savings-body">
        <div className="my-savings-categories-wrapper">
          <div className="my-savings-categories-container">
            <div className={`my-savings--action1 ${expandFilter && 'expanded'}`}>
              <img
                src={expandFilter ? threedotsWhite : threedots}
                alt="expand"
                className="my-savings--expand-icon"
                onClick={handleExpandFilter}
                ref={expandBtnRef}
              />
            </div>
            {!expandFilter && (
              <>
                <div className="my-savings-categories" ref={categoriesRef}>
                  {categoriesMySavings?.map((ct, idx) => {
                    return (
                      <div
                        className={`my-savings-category ${ct.displayCategory === selectedCategory?.displayCategory ? 'selected' : ''}`}
                        key={ct.displayCategory}
                        onClick={() => handleChangeCategory(ct)}>
                        {ct.displayCategory}
                      </div>
                    );
                  })}
                </div>{' '}
                <div className="offers-header--action2">
                  <img
                    src={preIcon}
                    alt="pre"
                    className={`offers-header--pre-icon ${isAtStart ? 'active' : ''}`}
                    onClick={handlePreScroll}
                  />
                  <img
                    src={nextIcon}
                    alt="next"
                    className={`offers-header--next-icon  ${isAtEnd ? 'active' : ''}`}
                    onClick={handleNextScroll}
                  />
                </div>
              </>
            )}
          </div>
          {expandFilter && (
            <div className="my-savings--expand-filter-container" ref={expandFilterRef}>
              {renderCategoryExpanded?.map((ct) => {
                return (
                  <div
                    className="my-savings--expand-filter-item"
                    key={ct.displayCategory}
                    onClick={() => handleChangeCategory(ct)}>
                    <img src={ct.blueIcon} alt="category" />
                    <div className="my-savings--expand-filter-item--title">{ct.displayCategory}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {savingsOffers?.length === 0 && (
          <div className="my-savings-empty-list">
            <div className="my-savings-empty-list--title">No Savings Recorded</div>
            <div className="my-savings-empty-list--desc">
              Your savings will be displayed when you have used you cards at the stores with activated offers.
            </div>
          </div>
        )}
        <div className="my-savings-list">
          {savingsOffers?.map((offer) => {
            return (
              <SavingOffer
                key={offer.userOfferCommissionDetailId}
                data={offer}
                handleClickItem={() => handleClickOffer(offer)}
                handleCheck={() => handleCheckOffer(offer)}
                checked={selectedOffers?.includes(offer.userOfferCommissionDetailId)}
              />
            );
          })}
        </div>
        {savingsOffers?.length > 0 && hasMore && (
          <div className="my-savings-load-more-btn" onClick={fetchMore}>
            View more ...
          </div>
        )}
      </div>
      {savingsOffers?.length > 0 && (
        <div className="redeem-link-btn" onClick={handleClickRedeemInApp} id="redeem-in-app">
          Redeem Savings in the App
        </div>
      )}
      <ModalSavingOfferDetail isOpen={!!offerDetail} onClose={handleCloseModal} data={offerDetail} />
      <ModalRedeemSavings
        isOpen={showModalRedeem}
        onClose={() => setShowModalRedeem(false)}
        total={sumRedeemAmount}
        handleContinue={handleContinue}
      />
      <ModalRedeemSubmit
        isOpen={showModalSubmit}
        onClose={() => setShowModalSubmit(false)}
        total={amountOffers}
        amount={sumRedeemAmount}
        commissionIds={selectedOffers}
        showModalTimeout={() => setShowModalTimeout(true)}
        setDataSocket={setDataSocket}
      />
      <ModalPayoutTimeout
        isOpen={showModalTimeout}
        onClose={() => {
          setShowModalTimeout(false);
          setDataSocket(null);
          handleRefreshData();
        }}
        data={dataSocket}
      />
      {savingsOffers?.length > 0 && (
        <PopoverDownloadApp
          target="redeem-in-app"
          isOpen={isOpenPopover}
          title="Redeem Savings in Uthrive App"
          placement="top"
          handleDone={() => setIsOpenPopover(false)}
          modalSize="md"
        />
      )}
    </div>
  );
};
