import nextIcon from 'assets/images/next.png';
import './style.scss';
import { ModalCustom } from 'components/ModalCustom';
import { useState } from 'react';

type props = {
  handleClickItem: () => void;
  name: string;
  bonus: string;
  spend: string;
  rewards: string;
  hasBonus: boolean;
  specialOffer: string;
  isBonus: boolean;
  hasTermApply: boolean;
};

export const RecommendedCard = ({
  handleClickItem,
  name,
  bonus,
  spend,
  rewards,
  hasBonus = false,
  specialOffer,
  isBonus = false,
  hasTermApply = false
}: props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickTerms = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    setOpenModal(true);
  };

  return (
    <div className="recommended-card-container" onClick={handleClickItem}>
      <div className="recommended-card-name-container">
        <div className="recommended-card-name">{name}</div>
        {hasTermApply && (
          <div className="recommended-card-name-terms" onClick={handleClickTerms}>
            Terms apply
          </div>
        )}
      </div>
      <div className="recommended-card-content">
        <div className="recommended-card-reward-container">
          {hasBonus ? (
            <>
              <div className="recommended-card-reward">
                <div className="recommended-card-reward--title">Bonus</div>
                <div className="recommended-card-reward--value">{bonus}</div>
              </div>
              <div className="recommended-card-reward">
                <div className="recommended-card-reward--title">Spend</div>
                <div className="recommended-card-reward--value">{spend}</div>
              </div>
              <div className="recommended-card-reward">
                <div className="recommended-card-reward--title">{isBonus ? 'Bonus Value' : 'Rewards'}</div>
                <div className="recommended-card-reward--value">{rewards}</div>
              </div>
            </>
          ) : (
            <div className="recommended-card-specialOffer">{specialOffer}</div>
          )}
        </div>
        <img src={nextIcon} alt="next" className="recommended-card-icon" />
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Advertising, Editorial and Terms Apply Disclosure</div>
          <div>
            Uthrive has partnered with CardRatings for our coverage of credit card products. Uthrive and CardRatings may
            receive a commission from card issuers.
            <br />
            <br />
            Some or all of the card offers that appear on the website are from advertisers. Also, compensation may
            impact how and where links appear on this site and this site does not include all financial companies or all
            available financial offers.
            <br />
            <br />
            Opinions, reviews, analyses & recommendations are the author&apos;s alone, and have not been reviewed,
            endorsed or approved by any other entities.
            <br />
            <br />
            *Please review details about the offer, benefits and qualification criteria on the issuer&apos;s card
            application page carefully before submitting your card application, as they are subject to change at
            issuer&apos;s sole discretion.
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};
