import {
  IsearchResult,
  OffersDataRecordType,
  ParamsGetOffersType,
  ParamsSearchOfferType,
  offerMerchantType,
  IParamsOfferMerchantDetails,
  ParamsGetTotalCommisstionsType,
  ParamsGetOffersMerchantSavingsType,
  ParamsRedeemCommissionType,
  categoryRecordType
} from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

export const getOffers = async ({
  category,
  limit = 10,
  offset = 0,
  search = ''
}: ParamsGetOffersType): Promise<OffersDataRecordType[]> => {
  const params = { limit, offset, search };
  const resp = await axiosClient.get(`${endpoints.getOffersByCategory}/${category}`, { params });
  return resp.data.data;
};

export const getOffersFiltered = async ({
  category,
  limit = 10,
  offset = 0,
  search = ''
}: ParamsGetOffersType): Promise<offerMerchantType[]> => {
  const params = { limit, offset, search };
  const resp = await axiosClient.get(`${endpoints.getOffersByCategory}/${category}`, { params });
  return resp.data.data;
};

export const getCategories = async (): Promise<categoryRecordType[]> => {
  const resp = await axiosClient.get(endpoints.getCategories);
  return resp.data.data;
};

export const getCategoriesForAll = async (): Promise<categoryRecordType[]> => {
  const resp = await axiosClient.get(endpoints.getCategoriesForAll);
  return resp.data.data;
};

export const searchOffers = async ({
  limit = 10,
  offset = 0,
  search = ''
}: ParamsSearchOfferType): Promise<IsearchResult[]> => {
  const params = { limit, offset, search };
  const resp = await axiosClient.get(`${endpoints.searchOffer}`, { params });
  return resp.data.data;
};

export const getOffersMerchantDetails = async (params: IParamsOfferMerchantDetails) => {
  const resp = await axiosClient.get(endpoints.offerMerchantDetails, { params });
  return resp.data.data;
};

export const pinMerchant = async (merchant: string) => {
  const resp = await axiosClient.post(endpoints.pinMerchant, { mappedMerchant: merchant });
  return resp.data;
};

export const unPinMerchant = async (merchant: string) => {
  const resp = await axiosClient.post(endpoints.unPinMerchant, { mappedMerchant: merchant });
  return resp.data;
};

//paypal redeem

export const getTotalCommissions = async (params: ParamsGetTotalCommisstionsType) => {
  const resp = await axiosClient.get(endpoints.totalCommissions, { params });
  return resp.data;
};

export const getOffersMerchantSavings = async (params: ParamsGetOffersMerchantSavingsType) => {
  const resp = await axiosClient.get(endpoints.offersMerchantMySavings, { params });
  return resp.data;
};

export const redeemCommission = async (params: ParamsRedeemCommissionType) => {
  const resp = await axiosClient.post(endpoints.redeemOffer, params);
  return resp.data;
};

export const getPayoutHistories = async (params: { offset: number; limit: number }) => {
  const resp = await axiosClient.get(endpoints.redeemHistory, { params });
  return resp.data;
};

export const getPayoutHistoryDetail = async (id: string) => {
  const resp = await axiosClient.get(`${endpoints.redeemDetail}/${id}`);
  return resp.data;
};
