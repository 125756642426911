import { Fragment, useEffect, useMemo } from 'react';
import './style.scss';
import { getCards } from 'services';
import { useAppStore, useCardsStore } from 'store';
import { FeaturedCard, ScrollToTop } from 'components';
import { useNavigate } from 'react-router-dom';
import { cardColors } from 'const';
import { findIndex, get } from 'lodash';
import chevLeft from 'assets/images/chevron-left.svg';

export const Cards = () => {
  const navigation = useNavigate();

  const { featuredCards, setFeaturedCards } = useCardsStore();
  const { setAppLoading } = useAppStore();

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      setAppLoading(true);
      const resp = await getCards();
      setFeaturedCards(resp?.data || []);
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleClickCard = (id: number) => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    navigation(`/cards/${id}`);
  };

  const handleBackBtn = () => {
    navigation('/', { replace: true });
  };

  const handleClickUserSurvey = () => {
    navigation('/user-survey');
  };

  const findIndexOfFEATUREDTitle = useMemo(() => {
    return findIndex(featuredCards, (e) => get(e, 'source') === 'FEATURED');
  }, [featuredCards]);

  return (
    <div className="cards-page-container">
      <div className="cards-page-body">
        <button className="cards-page-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <div className="cards-page-btn-personal d-none" onClick={handleClickUserSurvey}>
          Personalize
        </div>
        <div className="cards-page-contents">
          {featuredCards?.map((card, idx) => {
            if (idx === 0 || idx === findIndexOfFEATUREDTitle) {
              return (
                <Fragment key={card.mappedCardId}>
                  {idx === findIndexOfFEATUREDTitle && idx !== 0 && <div className="line-divider" />}
                  <div className="cards-page--title">
                    {get(card, 'source') === 'FEATURED' ? (
                      <>
                        Cards popular with <span>others</span> like you.
                      </>
                    ) : (
                      <>
                        Best cards based on <span>your</span> spend habits.
                      </>
                    )}
                  </div>
                  <FeaturedCard
                    key={card?.mappedCardId}
                    data={card}
                    onClick={handleClickCard}
                    footerBackgroundColor={cardColors[idx % 3]}
                  />
                </Fragment>
              );
            }
            return (
              <FeaturedCard
                key={card?.mappedCardId}
                data={card}
                onClick={handleClickCard}
                footerBackgroundColor={cardColors[idx % 3]}
              />
            );
          })}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};
