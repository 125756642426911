import { useEffect, useState } from 'react';
import arrowUp from 'assets/images/arrow-up.svg';
import './style.scss';

export const ScrollToBottom = () => {
  const [showGoDown, setShowGoDown] = useState(false);

  const handleVisibleButton = () => {
    // setShowGoDown(window.pageYOffset > 50);
    setShowGoDown(true);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 300, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
  }, []);

  return (
    <div className={showGoDown ? 'goTopHidden' : ''} onClick={handleScrollUp}>
      <button type={'button'} className="goDown">
        <img src={arrowUp} alt="Arrow Up" />
      </button>
    </div>
  );
};
