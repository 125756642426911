import './style.scss';

type props = {
  title: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  id?: string;
};

export const PopupNotify = ({ title, onClickYes, onClickNo, id = 'btn-id' }: props) => {
  return (
    <>
      <div className="notify-common">
        <div className="notify-common-text">{title}</div>
        <div className="notify-common-prompt">
          <button className="notify-common-button" onClick={onClickYes} id={id}>
            Yes, take me there.
          </button>
          <button className="notify-common-button" onClick={onClickNo}>
            No
          </button>
        </div>
      </div>
    </>
  );
};
