import './style.scss';

export const LoadingOffers = ({ isShowTitle = true }: { isShowTitle?: boolean }) => {
  return (
    <div className="loading-offers">
      <div className="premium-offers-contents">
        {isShowTitle && <div className="loading-effect" />}
        <div className="offer-container">
          {[1, 2, 3, 4, 5]?.map((idx) => {
            return <div className="loading-offer-effect" key={idx} />;
          })}
        </div>
      </div>
    </div>
  );
};
