import React, { useState } from 'react';
import nextIcon from 'assets/images/next.png';
import './style.scss';
import { EarnedRewardType } from 'types';
import { formatMoney } from 'utils';
import { PopoverDownloadApp } from 'components/PopoverDownloadApp';

type props = {
  data: EarnedRewardType;
  isSeeMore?: boolean;
  handleClick: () => void;
};

export const EarnedRewardsBoxItem = ({ data, handleClick, isSeeMore = false }: props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="earned-reward-item">
      <div>
        <h3 className="earned-reward-title">{`${data?.name} ${data?.accountMask ? ` - ${data?.accountMask}` : ''}`}</h3>
        {data?.accountStatus !== null && !isSeeMore && (
          <>
            <div
              className="earned-reward-link"
              id={`renew-${data?.id}`}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}>
              Renew bank connection
            </div>
            <PopoverDownloadApp
              title="Renew connection in the Uthrive App"
              isOpen={isOpen}
              target={`renew-${data?.id}`}
              handleDone={() => setIsOpen(false)}
            />
          </>
        )}
      </div>

      <div className="earned-reward-infoBox">
        <div className="earned-reward-image">
          <img src={data?.accountImageUrl} alt="category" />
        </div>
        <div className="earned-reward-sm">
          <div className="earned-reward-sm-item">
            <span className="earned-reward-sm-title">
              Net
              <br />
              Spent
            </span>
            <p className="earned-reward-sm-price">${formatMoney(data?.totalSpent)}</p>
          </div>
          <div className="earned-reward-sm-item">
            <span className="earned-reward-sm-title">
              Earned
              <br />
              Rewards
            </span>
            <p className="earned-reward-sm-price">${formatMoney(data?.totalRewards)}</p>
          </div>
        </div>
      </div>
      <div className="earned-reward-nextIcon" onClick={handleClick}>
        <img src={nextIcon} alt="next"></img>
      </div>
    </div>
  );
};
