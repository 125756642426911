import searchIcon from 'assets/images/search-icon.png';
import './style.scss';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IsearchResult } from 'types';
import iconClose from 'assets/images/nav-toggle-close.png';
import { Spinner } from 'reactstrap';

type props = {
  value: string;
  onChange: (val: string) => void;
  searchResults: IsearchResult[];
  handleSelectResult: (val: IsearchResult) => void;
  handleClickClear?: () => void;
  isSearching?: boolean;
  handleClickNoResult?: () => void;
};

const useOutsideClick = <T extends HTMLElement>(callback: any) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);

  return ref;
};

export const SearchInput = ({
  value,
  onChange,
  handleSelectResult,
  searchResults,
  handleClickClear,
  isSearching = false,
  handleClickNoResult
}: props) => {
  const [showSearchResults, setShowSearchResults] = useState(false);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange(value);
  };

  const handleClickOutSide = () => {
    setShowSearchResults(false);
  };

  const handleFocus = () => {
    setShowSearchResults(true);
  };

  const handleSelected = (val: IsearchResult) => {
    setShowSearchResults(false);
    handleSelectResult(val);
  };

  const ref = useOutsideClick<HTMLDivElement>(handleClickOutSide);

  const handleHighlightSearchResults = (str: string, data: IsearchResult[]) => {
    const newArr = data
      .filter((item) => item?.offerMerchant?.toLowerCase().includes(str.toLowerCase()))
      .map((item) => {
        const newText = item?.offerMerchant.replace(
          new RegExp(str, 'gi'),
          (match: string) => `<strong>${match}</strong>`
        );

        return { ...item, offerMerchantFormated: newText };
      });
    return newArr;
  };

  const renderSearchResults = handleHighlightSearchResults(value, searchResults);
  return (
    <div className="search-input-container" ref={ref}>
      <div className="search-input--input-container">
        <img src={searchIcon} alt="icon" className="search-icon" />
        <input
          className="search-input"
          placeholder="Search offers"
          value={value}
          onChange={handleChangeValue}
          onFocus={handleFocus}
        />
      </div>
      {value?.trim()?.length >= 1 && !!handleClickClear && (
        <img src={iconClose} alt="icon" className="search-input--clear" onClick={handleClickClear} />
      )}
      {value && showSearchResults && (
        <div className="search-results">
          {isSearching ? (
            <div className="search-loading-container">
              <Spinner
                color="primary"
                style={{
                  height: '2rem',
                  width: '2rem'
                }}
              />
            </div>
          ) : (
            <>
              {renderSearchResults?.map((e) => {
                return (
                  <div
                    className="search-result"
                    key={e.offerMerchant}
                    onClick={() => handleSelected(e)}
                    dangerouslySetInnerHTML={{ __html: e.offerMerchantFormated }}
                  />
                );
              })}
              {!value || value?.length < 2 ? (
                <div className="no-result">Enter at least 2 letters</div>
              ) : (
                renderSearchResults?.length === 0 && (
                  <div className="no-result-link" onClick={handleClickNoResult ? handleClickNoResult : undefined}>
                    Couldn&apos;t find what you were looking for?
                  </div>
                )
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
