import { SavingOfferType } from 'types';
import './style.scss';
import nextIcon from 'assets/images/next.png';
import { fixMoney } from 'utils';
import moment from 'moment';
import { Input } from 'reactstrap';
import tempIcon from 'assets/images/gift-icon.svg';
type props = {
  data: SavingOfferType;
  handleClickItem: () => void;
  checked?: boolean;
  handleCheck: () => void;
};

export const SavingOffer = ({ data, handleClickItem, checked = false, handleCheck }: props) => {
  return (
    <div className="saving-offer-item">
      <div className="saving-offer-item--col1">
        <Input
          className="saving-offer-checkbox"
          type="checkbox"
          checked={checked}
          onChange={handleCheck}
          disabled={data?.userOfferCommissionStatus !== 'READY'}
        />
        <img
          src={data.offerMerchantLogoUrl ? data.offerMerchantLogoUrl : tempIcon}
          alt="logo"
          className={`saving-offer-merchant ${!data.offerMerchantLogoUrl ? 'temp-logo' : ''}`}
        />
        <div className="saving-offer-info-container">
          <div className="saving-offer-name">
            <div className="name">{data.offerMerchant}</div>
            <div className="reward">{`Spent: $${fixMoney(data.userOfferSaleAmount)}`}</div>
            <div className="expired-time">{`Date: ${moment(data.userOfferCommissionTransactionDate).format('ddd, MM/YY')}`}</div>
          </div>
        </div>
      </div>
      <div className="saving-offer-item--col2" onClick={handleClickItem}>
        <div className="saving-offer-item--end-container">
          <div className="saving-offer-item--amount">${fixMoney(data.endUserCommissionAmount)}</div>
          <img src={nextIcon} alt="next" className="icon-next" />
        </div>
        <div className="saving-offer-item--status">{data.userOfferCommissionStatus}</div>
      </div>
    </div>
  );
};
