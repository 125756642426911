import { compareRewardsParamsType } from 'types';
import axiosClient from './axiosClient';
import { endpoints } from './endpoints';

export const getCards = async () => {
  const resp = await axiosClient.get(endpoints.cards);
  return resp.data;
};

export const getCardDetails = async (id: string) => {
  const resp = await axiosClient.get(`${endpoints.recommendedCard}/${id}`);
  return resp.data;
};

export const reactionCard = async ({ cardId, reactionType }: { cardId: string; reactionType: 'N' | 'Y' }) => {
  const resp = await axiosClient.post(endpoints.reactionCard, {
    recommendedMappedCardId: parseInt(cardId),
    userLikeResponse: reactionType
  });
  return resp.data;
};

export const getCardAll = async () => {
  const resp = await axiosClient.get(endpoints.recommendedCardAll);
  return resp.data;
};

export const compareRewards = async (params: compareRewardsParamsType) => {
  const resp = await axiosClient.get(endpoints.compareRewards, { params });
  return resp.data;
};
