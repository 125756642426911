import { CustomButton } from 'components/Button';
import { ModalCustom } from 'components/ModalCustom';
import { useState } from 'react';
import { Input } from 'reactstrap';
import { contactAddMoreMerchant } from 'services';
import { useAppStore } from 'store';
import imageModal from 'assets/images/image_modal.png';
import sendSuccess from 'assets/images/email-ok.png';
import './style.scss';

type props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ModalContactAddMerchant = ({ isOpen, onClose }: props) => {
  const { setAppLoading } = useAppStore();

  const [value, setValue] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const handleSubmit = async () => {
    try {
      setAppLoading(true);
      await contactAddMoreMerchant({ caseType: 'ADD_MORE_MERCHANT', caseDescription: value });
      setValue('');
      setOpenModalSuccess(true);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleResubmit = () => {
    setOpenModalSuccess(false);
  };

  const handleClose = () => {
    setValue('');
    setOpenModalSuccess(false);
    onClose();
  };

  return (
    <ModalCustom isOpen={isOpen} onClose={handleClose} toggle={handleClose}>
      {!openModalSuccess ? (
        <div className="modal-add-merchant-contact">
          <div className="modal-add-merchant-contact--title">We are working on it.</div>
          <div className="modal-add-merchant-contact--text">
            We are working on to add more merchants, please bear with us.
            <br />
            if you would like any merchants to be added, please submit it here.
          </div>
          <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder="Enter at least 3 characters" />
          <CustomButton onClick={handleSubmit} disabled={value?.trim()?.length === 0}>
            Submit
          </CustomButton>
          <img className="modal-add-merchant-contact--img" src={imageModal} alt="working" />
        </div>
      ) : (
        <div className="modal-add-merchant-contact">
          <div className="modal-add-merchant-contact--title">Thanks, we will take it from here.</div>
          <img className="modal-add-merchant-contact--icon" src={sendSuccess} alt="mail" />
          <CustomButton onClick={handleResubmit}>Submit another merchant</CustomButton>
        </div>
      )}
    </ModalCustom>
  );
};
