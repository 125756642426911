import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Label, Input } from 'reactstrap';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import emailOk from 'assets/images/email-ok.png';
import { CustomButton } from 'components';
import { useAppStore, useAuthStore } from 'store';
import { contactUs } from 'services';

type props = {
  cb?: () => void;
};

const ContactForm = ({ cb }: props) => {
  const { userConfig } = useAuthStore();
  const { setAppLoading } = useAppStore();

  const [count, setCount] = useState(0);
  const [value, setValue] = useState('');

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value);
    setCount(e.target.value?.trim()?.length);
  };
  const handleSendFeedback = async () => {
    try {
      setAppLoading(true);
      await contactUs({ caseDescription: value?.trim() });
      setAppLoading(false);
      if (cb) cb();
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };
  return (
    <>
      <p className="contactus-desc">
        Get help from experts at Uthrive – typically responds in 2-5 hrs. For urgent help, please call{' '}
        <span className="highlight">{userConfig?.callContactNumber}</span> {userConfig?.callContactTime}.
      </p>
      <FormGroup>
        <Label className="contactus-label">Message</Label>
        <Input
          className="contactus-textarea"
          id="exampleText"
          name="text"
          type="textarea"
          onChange={handleChangeValue}
          maxLength={4000}
          value={value}
        />
        <p className={`contactus-count ${count === 4000 ? 'maxed' : ''}`}>{count}/4000</p>

        <div className="contactus-submitBox">
          <CustomButton type="submit" onClick={handleSendFeedback} disabled={!value?.trim()}>
            Send
          </CustomButton>
        </div>
      </FormGroup>
    </>
  );
};

const ThankYouForm = ({ onClickHandler }: { onClickHandler: () => void }) => {
  return (
    <>
      <div className="contactus-thankYou">
        <h2>Thank you, we received your message</h2>
        <img className="icon" src={emailOk} alt="Email Ok" />
        <p>
          Thank you for reaching out. We will get
          <br /> back to you as soon as we can.
        </p>
      </div>
      <div className="contactus-submitBox">
        <CustomButton type="submit" onClick={onClickHandler}>
          Back
        </CustomButton>
      </div>
    </>
  );
};

export const ContactUs = () => {
  const navigate = useNavigate();
  const [send, setSend] = useState(false);
  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  const handleSendBtn = () => {
    setSend((prevState) => !prevState);
  };

  return (
    <div className="contactus">
      <div className="contactus-container">
        <div className="contactus-header">
          <button className="contactus-btnBack" onClick={handleBackBtn}>
            <img src={chevLeft} alt="chevron-left" />
            Back
          </button>
          <h1>Contact Us</h1>
        </div>
        <div className="contactus-body">
          {send !== true ? <ContactForm cb={handleSendBtn} /> : <ThankYouForm onClickHandler={handleSendBtn} />}
        </div>
      </div>
    </div>
  );
};
