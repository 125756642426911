import { useEffect } from 'react';
import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { useAppStore, useAuthStore } from 'store';
import { get, isEmpty } from 'lodash';
import { Input, Label } from 'reactstrap';
import { CustomButton } from 'components/Button';
import PlacesAutocompleteGroup from 'components/PlaceAutoComplete';
import { getAddressHelper } from 'utils/common';
import { Controller, useForm } from 'react-hook-form';
import { getMe, getProfile, verifyUserInfo } from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

type props = {
  isOpen: boolean;
};

export const ModalUpdateUser = ({ isOpen }: props) => {
  const navigate = useNavigate();

  const { user, setUser, setUserProfile, userProfile } = useAuthStore();
  const { setAppLoading } = useAppStore();

  const isEmailAuthen =
    !get(user, 'linkedGoogleLoginId') && !get(user, 'linkedFbLoginId') && !get(user, 'linkedAppleLoginId');

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    streetAddress: yup.string().required(),
    apartmentAddress: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    zipCode: yup.string().required().min(5).max(5),
    phoneNumber: isEmailAuthen ? yup.string() : yup.string().required().min(10)
  });

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      streetAddress: '',
      apartmentAddress: '',
      city: '',
      state: '',
      zipCode: '',
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      phoneNumber: ''
    },
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setValue('firstName', userProfile.firstName);
    setValue('lastName', userProfile.lastName);
  }, [userProfile]);

  const checkAddress = watch('streetAddress');
  const onClose = () => {
    console.log('close');
  };
  const submitInfo = async (data: any) => {
    try {
      setAppLoading(true);
      const param = { ...data };
      if (param.phoneNumber && !isEmailAuthen) {
        param.phoneNumber = '+1' + param.phoneNumber.replace(/-/gi, '');
      } else {
        param.phoneNumber = null;
      }
      await verifyUserInfo(param);
      const resp = await Promise.all([getMe(), getProfile()]);
      setUser(resp[0]);
      setUserProfile(resp[1]?.data);
      setAppLoading(false);
      navigate('/');
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const onChangeData = ({ name, value }: any) => {
    console.log({ name, value });

    const addressDetail = getAddressHelper(value ? value[0] : null);
    const streetAddress = addressDetail.streetAddress;
    const streetNumber =
      addressDetail.streetNumber && !isNaN(Number.parseFloat(addressDetail.streetNumber))
        ? Number.parseFloat(addressDetail.streetNumber)
        : 0;
    const apartmentAddress = addressDetail.apartmentAddress;
    const city = addressDetail.city;
    const state = addressDetail.state;
    const zipCode = addressDetail.zipCode;
    setValue('streetAddress', `${streetNumber} ${streetAddress}`, { shouldValidate: true });
    setValue('apartmentAddress', apartmentAddress, { shouldValidate: true });
    setValue('city', city, { shouldValidate: true });
    setValue('state', state, { shouldValidate: true });
    setValue('zipCode', zipCode, { shouldValidate: true });
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      onClose={onClose}
      toggle={onClose}
      isSupportClose={false}
      className="modal-update-user">
      <div className="modal-update-user-info">
        <div className="modal-update-user-info--title">
          {!isEmailAuthen ? (
            <>
              Welcome, <span>{userProfile?.firstName ? userProfile?.firstName : 'User'}</span>!
            </>
          ) : (
            'Welcome!'
          )}{' '}
          Your Account is Successfully Created.
        </div>
        <div className="modal-update-user-info--title mt-4">One last thing..</div>
        <div className="modal-update-user-info--text mt-3">
          So that we can address you properly and present best local offers around you, please confirm the following:
        </div>
        <div className="row mt-5" style={{ maxHeight: '380px' }}>
          <div className="row mb-3">
            <div className="col-6">
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => <Input {...field} className={errors.firstName && 'red-highlight'} />}
              />

              <Label className={errors.firstName && 'red-highlight-text'}>First name</Label>
            </div>
            <div className="col-6">
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => <Input {...field} className={errors.lastName && 'red-highlight'} />}
              />
              <Label className={errors.lastName && 'red-highlight-text'}>Last name</Label>
            </div>
          </div>
          {!isEmailAuthen && (
            <div className="row mb-3">
              <div className="col-12">
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { value } }) => (
                    <PatternFormat
                      value={value}
                      onChange={(e) => {
                        setValue('phoneNumber', e.target.value.replaceAll(/-/gi, '').replaceAll(/_/gi, ''), {
                          shouldValidate: true
                        });
                      }}
                      placeholder="***-***-****"
                      format="###-###-####"
                      mask="_"
                      className={`${errors.phoneNumber && 'red-highlight'} phone-validate form-control`}
                    />
                  )}
                />
                <Label className={errors.phoneNumber && 'red-highlight-text'}>Cell Phone#</Label>
              </div>
            </div>
          )}
          <div className="row mb-3">
            <PlacesAutocompleteGroup
              name="streetAddress"
              onChange={(value: any) => onChangeData(value)}
              wrapperClass={errors.streetAddress && 'red-highlight-address'}
            />
            <Label className={errors.streetAddress && 'red-highlight-text'}>Street Address</Label>
          </div>

          <div className="row" style={{ height: '70px' }}>
            {checkAddress && (
              <>
                <div className="col-3">
                  <Controller control={control} name="apartmentAddress" render={({ field }) => <Input {...field} />} />
                  <Label>Apartment#</Label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="city"
                    render={({ field }) => <Input {...field} className={errors.city && 'red-highlight'} />}
                  />
                  <Label className={errors.city && 'red-highlight-text'}>City</Label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="state"
                    render={({ field }) => <Input {...field} className={errors.state && 'red-highlight'} />}
                  />
                  <Label className={errors.state && 'red-highlight-text'}>State</Label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="zipCode"
                    render={({ field }) => (
                      <Input {...field} className={errors.zipCode && 'red-highlight'} maxLength={5} />
                    )}
                  />
                  <Label className={errors.zipCode && 'red-highlight-text'}>Zip Code</Label>
                </div>
              </>
            )}
          </div>
        </div>
        <CustomButton onClick={handleSubmit(submitInfo)} className="mt-5" disabled={!isEmpty(errors) || !isValid}>
          Confirm
        </CustomButton>
      </div>
    </ModalCustom>
  );
};
