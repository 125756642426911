import React from 'react';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';

import { useAuthStore } from 'store';
import { ProfileDataType } from 'types';

type props = {
  data: ProfileDataType;
  handleBack: () => void;
};

const ProfileView = ({ data, handleBack }: props) => {
  return (
    <>
      <div className="profile-header">
        <button className="profile-btnBack" onClick={handleBack}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <h1>Profile</h1>
        {/* <button className={`profile-btnEdit`} onClick={handleEditBtn}>
            {activeEdit === true ? 'Canel' : 'Edit'}
          </button> */}
      </div>
      <div className="profile-body">
        <div className="profile-infoBox">
          <div className="profile-infoItem">
            <Label className="profile-label">First name</Label>
            <p className="profile-value">{data.firstName}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Last name</Label>
            <p className="profile-value">{data.lastName}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Preferred name</Label>
            <p className="profile-value">{data.preferredName}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Middle initial</Label>
            <p className="profile-value">{data.middleInitial}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Street Address</Label>
            <p className="profile-value">{data.streetAddress}</p>
          </div>
          <div className="profile-infoItem"></div>
          <div className="profile-infoItem">
            <Label className="profile-label">Appartment #</Label>
            <p className="profile-value">{data.apartmentNumber}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">City</Label>
            <p className="profile-value">{data.city}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">State</Label>
            <p className="profile-value">{data.state}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Zip code</Label>
            <p className="profile-value">{data.zipcode}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Phone Number</Label>
            <p className="profile-value">{data.phoneNumber}</p>
          </div>
          <div className="profile-infoItem">
            <Label className="profile-label">Email Address</Label>
            <p className="profile-value">{data.email}</p>
          </div>
        </div>
      </div>
    </>
  );
};

// const ProfileEdit = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors }
//   } = useForm<Inputs>();
//   const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <div className="profile-infoBox">
//         <div className="profile-infoItem">
//           <Label className="profile-label">First name</Label>
//           <Input defaultValue="sam" {...register('firstName', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Last name</Label>
//           <Input defaultValue="gupta" {...register('lastName', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Preferred name</Label>
//           <Input defaultValue="" {...register('preferredName', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Middle initial</Label>
//           <Input defaultValue="" {...register('middleInitial')} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Street Address</Label>
//           <Input defaultValue="69 New England Ave" {...register('streetAddress', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem"></div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Appartment #</Label>
//           <Input defaultValue="" {...register('apartmentNo', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">City</Label>
//           <Input defaultValue="Summit" {...register('city', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">State</Label>
//           <Input defaultValue="NJ" {...register('state', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Zip code</Label>
//           <Input defaultValue="" {...register('zipCode', { required: true })} />
//           {errors.firstName && <span>This field is required</span>}
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Phone Number</Label>
//           <p className="profile-value">646-573-6182</p>
//         </div>
//         <div className="profile-infoItem">
//           <Label className="profile-label">Email Address</Label>
//           <p className="profile-value">thisissameer+245@gmail.com</p>
//         </div>
//       </div>
//       <div className="profile-submitBox">
//         <CustomButton type="submit">Confirm</CustomButton>
//       </div>
//     </form>
//   );
// };

export const Profile = () => {
  const navigate = useNavigate();

  const { userProfile } = useAuthStore();

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  const handleDeleteBtn = () => {
    navigate('/profile/delete', { replace: true });
  };

  return (
    <div className="profile">
      <div className="profile-container">
        <ProfileView data={userProfile} handleBack={handleBackBtn} />
        <button className="profile-btnDelete" onClick={handleDeleteBtn}>
          Delete Uthrive account
        </button>
      </div>
    </div>
  );
};
