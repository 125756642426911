import { ModalCustom } from 'components/ModalCustom';
import { PayoutHistoryDetailType } from 'types';
import './style.scss';
import { fixMoney, formatMoney } from 'utils';
import moment from 'moment';
import iconCopy from 'assets/images/copy.png';
import { toast } from 'react-toastify';

type props = {
  data: PayoutHistoryDetailType | null;
  isOpen: boolean;
  onClose: () => void;
};

export const ModalPayoutHistory = ({ data, isOpen = false, onClose }: props) => {
  if (!data) return null;

  const handleClickCopy = () => {
    navigator.clipboard.writeText(data.transactionId);
    toast.success('Copied!');
  };
  return (
    <ModalCustom
      isOpen={isOpen}
      className="custom-modal-payout-history-details"
      toggle={onClose}
      onClose={onClose}
      isSupportClose>
      <div className="modal-payout-history-container">
        <div className="modal-payout-history-header">Redeem Detail</div>
        <div className="modal-payout-history-ref-container">
          <div className="modal-payout-history--ref">
            Ref# <span>{data.transactionId}</span>
          </div>
          <img onClick={handleClickCopy} src={iconCopy} alt="copy" />
        </div>
        <div className="modal-payout-history-status">
          Status: <span>{data.transactionStatus}</span>
        </div>
        <div className="modal-payout-history-redeem-amount">
          <div className="modal-payout-history-redeem-amount--title">Redeem Amount:</div>
          <div className="modal-payout-history-redeem-amount--amount">${fixMoney(data.transactionAmount)}</div>
        </div>
        {data?.commissionItems?.map((item, idx) => {
          return (
            <div key={idx} className="modal-payout-history-commistion-item">
              <div>
                <div className="modal-payout-history-commistion-item--name">{item.offerMerchant}</div>
                <div className="modal-payout-history-commistion-item--spent">
                  Spent: ${formatMoney(item.spendAmount)}
                </div>
                <div className="modal-payout-history-commistion-item--date">
                  Date: {moment(item.transactionDateCommission).format('ddd, DD/MM/YYYY')}
                </div>
              </div>
              <div className="modal-payout-history-commistion-item--amount">${fixMoney(item.commissionAmount)}</div>
            </div>
          );
        })}
      </div>
    </ModalCustom>
  );
};
