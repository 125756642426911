import React from 'react';
import './style.scss';

export const LoaderThreeDots = () => {
  return (
    <div className="dots-loader-wrapper">
      <div className="dots-loader-container">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
