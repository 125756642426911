export const errTextOtp = 'Invalid code! Please check again the verification code we sent and re-enter.';
export const newCodeSent = 'A new one-time code is sent to you.';
export const accountNotFound = 'Account not found! Please check your email address once again.';
export const invalidEmail = 'Invalid email address!';
export const unknowErr = 'Something went wrong';
export const emailAlreadyExist = 'An account with this email exists.';
export const phoneAlreadyExist = 'This phone number is already taken.';
export const invalidPhone = 'Invalid Phone!';
export const expiredCode = 'Invalid code! Click Resend to get a new code.';
export const renewStatusMessage =
  'Your missed rewards on purchases are not being detected. Please relink accounts – takes just a few seconds.';
export const reactiveMessage =
  'You haven’t logged in a while. Refresh your linked cards to view latest rewards – takes just a few seconds.';
export const logoutMessage = 'We are securely signing you out.';
