import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { fixMoney } from 'utils';
import { CustomButton } from 'components/Button';
import iconCheck from 'assets/images/tick.png';
import { Button, Input, InputGroup } from 'reactstrap';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSocketRedeem } from 'hooks';
import { toast } from 'react-toastify';
import { redeemCommission } from 'services';
import logo from 'assets/images/logo512.png';
import bank from 'assets/images/blue-icon-bank.png';
import arrow from 'assets/images/arrow-blue.png';
import paypal from 'assets/images/paypal.png';
import { LoaderThreeDots } from 'components/LoaderThreeDots';
import { RedeemSocketPayload } from 'types';

type props = {
  isOpen: boolean;
  onClose: () => void;
  total: number;
  amount: number;
  commissionIds: string[];
  showModalTimeout: () => void;
  setDataSocket: (value: RedeemSocketPayload) => void;
};

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ModalRedeemSubmit = ({
  total = 0,
  amount = 0,
  isOpen = false,
  onClose,
  commissionIds,
  showModalTimeout,
  setDataSocket
}: props) => {
  const socket = useSocketRedeem();

  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);
  const [validEmail, setValidEmail] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const timeOut = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!socket) return;

    socket.on('payout.status', (cb: any) => {
      const data = cb?.data;
      console.log('socket data: ', data);
      showModalTimeout();
      setDataSocket(data);
      setShowLoader(false);

      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    });

    // retain connections in background
    setInterval(() => socket.emit('ping-pong'), 10000);
    return () => {
      if (timeOut.current) {
        return clearTimeout(timeOut.current);
      }
    };
  }, []);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleBlurEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!emailRegex.test(value)) {
      setValidEmail(false);
      return;
    }
    setValidEmail(true);
  };

  const handleConfirm = async () => {
    try {
      setShowLoader(true);
      setStep(step + 1);
      const params = {
        email: email,
        userOfferCommissionDtlIds: commissionIds
      };
      await redeemCommission(params);
      if (!timeOut.current) {
        timeOut.current = setTimeout(() => {
          showModalTimeout();
          setShowLoader(false);
          onClose();
          if (socket) {
            socket?.disconnect();
          }
        }, 15000);
      }
    } catch (err) {
      setShowLoader(false);
      console.log(err);
      toast.error('Something went wrong');
    }
  };

  const handleContinue = () => {
    setShowTerms(true);
  };

  const handleBack = () => {
    setShowTerms(false);
    onClose();
  };

  const handleClickCreatePayPal = () => {
    window.open('https://www.paypal.com/us/welcome/signup?locale.x=en_US', '_blank');
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      className="custom-modal-redeem-savings"
      toggle={onClose}
      onClose={onClose}
      isSupportClose>
      <div className="modal-redeem-savings-container">
        <div className="modal-redeem-savings-title justify-content-center">Redeem Savings</div>
        <div className="modal-redeem-savings-steps">
          <div className={`modal-redeem-savings-steps--step ${step >= 1 ? 'completed' : ''}`}>1</div>
          <div className="modal-redeem-savings-steps--line" />
          <div className={`modal-redeem-savings-steps--step ${step >= 2 ? 'completed' : ''}`}>2</div>
        </div>
        {step === 1 ? (
          <>
            <div className="modal-redeem-savings-amount-container">
              <div className="modal-redeem-savings-amount-title">
                <img src={iconCheck} alt="check" />
                <div>Verify Amount</div>
              </div>
              <InputGroup>
                <Input value={`$${amount}`} disabled />
                <Button onClick={onClose}>Edit</Button>
              </InputGroup>
              <div className="modal-redeem-savings-amount-redeem-value">Ready for Redeem: ${fixMoney(total)}</div>
            </div>
            <div className="modal-redeem-savings-email-container">
              <div className="modal-redeem-savings-email--label-container">
                {validEmail && <img src={iconCheck} alt="check" />}
                <div className="modal-redeem-savings-email--label">Your email linked to Paypal</div>
              </div>
              <Input
                value={email}
                onChange={handleChangeEmail}
                onBlur={handleBlurEmail}
                placeholder="Enter you PayPal ID"
              />
              {!validEmail && !!email && <div className="error-text">Invalid email. Try again.</div>}
            </div>
            <div className="modal-redeem-savings-do-not-account">
              Don&apos;t have PayPal Account? <span onClick={handleClickCreatePayPal}>Create now.</span>
            </div>
            {!showTerms ? (
              <div className="modal-redeem-savings-note-container">
                <div className="modal-redeem-savings-note-title">Important Note</div>
                <div className="modal-redeem-savings-desc">
                  Please very carefully validate the email entered above to be used for payout via Paypal.
                  <br />
                  <br />
                  In case of any mismatch of email entered above and resulting in payout processed in a different
                  account - Uthrive will not be held responsible.
                </div>
              </div>
            ) : (
              <div className="modal-redeem-savings-note-container">
                <div className="modal-redeem-savings-note-subtitle">Agree to Terms & Conditions</div>
                <div className="modal-redeem-savings-desc">
                  I agree to Terms & Conditions in place from Uthrive, Inc. as well as Terms & Conditions of PayPal Inc.
                  for Facilitating the delivery of above savings amount.
                  <br />
                  <br />
                  If Uthrive, Inc. in its sole discretion determines that you engaged, attempt or intent to commit
                  fraud, you agree to be subjected to recovery of paid amount, and appropriate laga action or both.
                </div>
                <div className="modal-redeem-savings-note-container-btn-group">
                  <CustomButton onClick={handleBack}>Back</CustomButton>
                  <CustomButton disabled={!email || (!!email && !validEmail)} onClick={handleConfirm}>
                    Agree & Confirm
                  </CustomButton>
                </div>
              </div>
            )}
            {!showTerms && (
              <CustomButton disabled={!email || (!!email && !validEmail)} onClick={handleContinue}>
                Continue
              </CustomButton>
            )}
          </>
        ) : (
          <div className="modal-redeem-savings-step2-container">
            <div className="modal-redeem-savings-step2-title">
              Your redeemption of <span>${amount}</span> from your savings is in progress.
            </div>
            <div className="modal-redeem-savings-step2-loader">{showLoader && <LoaderThreeDots />}</div>
            <div className="modal-redeem-savings-progress-payout">
              <div className="progress">
                <img src={logo} alt="uthrive" />
              </div>
              <img src={arrow} alt="arrow" />
              <div className="progress">
                <img src={paypal} alt="paypal" />
              </div>
              <img src={arrow} alt="arrow" />
              <div className="progress">
                <img src={bank} alt="bank" />
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalCustom>
  );
};
