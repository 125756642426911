import OTPInput from 'react-otp-input';
import './style.scss';
import { memo } from 'react';

type props = {
  value: string;
  handleChage: (value: string) => void;
  numInputs?: number;
};

export const OtpInput = memo(({ value, handleChage, numInputs = 6 }: props) => {
  return (
    <OTPInput
      value={value}
      onChange={handleChage}
      numInputs={numInputs}
      inputStyle="otp-input"
      renderInput={(props) => <input {...props} />}
    />
  );
});
