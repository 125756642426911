import { AuthenticationStoreType } from 'types';
import { create } from 'zustand';
import { useAppStore } from './appStore';
import { useCardsStore } from './cardsStore';

const userKey = 'user';
const tokenKey = 'token';

const defaultLocation = {
  hash: '',
  key: 'default',
  pathname: '',
  search: '',
  state: null
};

const initUserProfile = {
  apartmentNumber: '',
  city: '',
  email: '',
  firstName: '',
  lastName: '',
  middleInitial: '',
  phoneNumber: '',
  preferredName: '',
  state: '',
  streetAddress: '',
  zipcode: ''
};

const getInitialLoggedIn = () => {
  const user = localStorage.getItem(userKey) || null;
  return user ? JSON.parse(user) : null;
};

const getTokenLoggedIn = () => {
  const token = localStorage.getItem(tokenKey) || null;
  return token ? JSON.parse(token) : null;
};

const getBrowserLocation = () => {
  const browserLocation = localStorage.getItem('browserLocation') || null;
  return browserLocation ? JSON.parse(browserLocation) : defaultLocation;
};

export const useAuthStore = create<AuthenticationStoreType>((set, get) => ({
  user: getInitialLoggedIn(),
  userProfile: initUserProfile,
  token: getTokenLoggedIn(),
  loading: false,
  userConfig: null,
  browserLocation: getBrowserLocation(),
  setLoading: (value) => set({ loading: value }),
  setUser: (value) =>
    set(() => {
      localStorage.setItem(userKey, JSON.stringify(value));
      return { user: value };
    }),
  setUserProfile: (value) =>
    set(() => {
      return { userProfile: value };
    }),
  setToken: (value) =>
    set(() => {
      localStorage.setItem(tokenKey, JSON.stringify(value));
      return { token: value };
    }),
  doLogout: () =>
    set(() => {
      localStorage.setItem(tokenKey, '');
      localStorage.setItem(userKey, '');
      useAppStore.getState().reset();
      useCardsStore.getState().reset();
      localStorage.removeItem('isCloseBanner');

      setTimeout(() => {
        get().resetBrowserLocation();
      }, 1000);

      return {
        user: null,
        token: null,
        userConfig: null
      };
    }),
  setUserConfig: (value) => set({ userConfig: value }),
  setBrowserLocation: (value) =>
    set(() => {
      localStorage.setItem('browserLocation', JSON.stringify(value));
      return { browserLocation: value };
    }),
  resetBrowserLocation: () =>
    set(() => {
      localStorage.setItem('browserLocation', JSON.stringify(defaultLocation));
      return { browserLocation: defaultLocation };
    })
}));
