import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAppStore, useAuthStore } from 'store';

export const useDetectIdleTimer = () => {
  const { token, doLogout, user } = useAuthStore();
  const { setAppLoading } = useAppStore();

  const isEmailAuthen =
    !get(user, 'linkedGoogleLoginId') && !get(user, 'linkedFbLoginId') && !get(user, 'linkedAppleLoginId');

  useEffect(() => {
    if (localStorage.getItem('activityTimeout') === 'true') {
      localStorage.removeItem('activityTimeout');

      window.location.replace('https://www.uthrive.club/');
    }
  }, []);

  const onIde = async (event?: Event) => {
    if (!token) {
      return;
    }
    try {
      if (!isEmailAuthen) {
        localStorage.setItem('activityTimeout', 'true');
      }
      setAppLoading(true);
      doLogout();
      await Auth.signOut();
      setAppLoading(false);
      if (isEmailAuthen) {
        window.location.replace('https://www.uthrive.club/');
      }
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const useIdleTimerData = useIdleTimer({
    onIdle: onIde,
    timeout: 300000
  });

  return useIdleTimerData;
};
