import { useEffect, useRef } from 'react';

export const useOutsideClick = <T extends HTMLElement>(callback: any, excluded: any) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current?.contains(event.target as Node) &&
        !excluded?.current?.contains(event.target as Node)
      ) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);

  return ref;
};
