import React, { useEffect, useLayoutEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AppRoute } from 'router';
import { Amplify } from 'aws-amplify';
import { useAuthStore } from 'store';
import { getMe, getMySubscriptions, getProfile, getUserConfig } from 'services';
import { AppLoading, ModalUpdateUser } from 'components';
import { useAppStore } from 'store/appStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDetectIdleTimer } from 'hooks';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN_SOCIAL_AUTHEN_URL,
      redirectSignIn: isLocalhost ? 'http://localhost:8668' : process.env.REACT_APP_REDICRECT_SOCIAL_AUTHEN_URL,
      redirectSignOut: isLocalhost ? 'http://localhost:8668' : process.env.REACT_APP_REDICRECT_SOCIAL_AUTHEN_URL,
      responseType: 'code'
    }
  }
});

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useDetectIdleTimer();

  const { setUser, token, loading, setLoading, setUserConfig, setToken, user, setUserProfile } = useAuthStore();
  const { appLoading, setSubscriptions } = useAppStore();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [location.pathname]);

  useEffect(() => {
    if (!location?.search) return;
    const queryParams = new URLSearchParams(location?.search);
    const tokenParams = queryParams.get('token');
    if (!tokenParams) return;
    setToken(tokenParams);
    navigate('/');
  }, [location]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const resps = await Promise.all([getMe(), getUserConfig(), getMySubscriptions(), getProfile()]);

      setUser(resps[0]);
      setUserConfig(resps[1]);
      setSubscriptions(resps[2]);
      setUserProfile(resps[3]?.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <>
      {(loading || appLoading) && <AppLoading />}
      <AppRoute />
      <ToastContainer autoClose={2000} toastStyle={{ top: 90 }} />
      {user && token && <ModalUpdateUser isOpen={!user?.accountVerification?.accountVerification} />}
    </>
  );
}

export default App;
