export const linkDownLoadExtension = [
  {
    key: 'chrome',
    label: 'Add to Chrome',
    value:
      'https://chrome.google.com/webstore/detail/uthrive-use-best-cards-fo/kmajiclhfhbncoegkfboeldogkdbmkhj?hl=en-US'
  },
  {
    key: 'edge',
    label: 'Add to Microsoft Edge',
    value: 'https://microsoftedge.microsoft.com/addons/detail/uthrive-use-best-cards-f/dnhdlmmgfndagkilkidkkjmeepkpnpdd'
  },
  {
    key: 'firefox',
    label: 'Add to Firefox',
    value:
      'https://addons.mozilla.org/en-US/firefox/addon/uthrive-maximize-rewards/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search'
  },
  {
    key: 'safari',
    label: 'Add to Safari',
    value: 'https://apps.apple.com/vn/app/uthrive-maximize-rewards/id6443603960?mt=12'
  }
];

export const listIssue = [
  {
    label: 'Privacy concerns',
    value: 'privacy-concerns'
  },
  {
    label: 'I use other cash back or coupon sites',
    value: 'cash-back-coupon-sites'
  },
  {
    label: 'No savings',
    value: 'no-savings'
  },
  {
    label: 'The Uthrive window shows too often',
    value: 'honey-window-too-often'
  },
  {
    label: 'It slows down my computer',
    value: 'slows-computer-input'
  },
  {
    label: 'Work computer',
    value: 'work-computer'
  },
  {
    label: 'Other, please explain:',
    value: 'other'
  }
];
