import './style.scss';
import logoCheck from 'assets/images/uthrive-icon-success.png';

export const AppLoadingSuccess = () => {
  return (
    <>
      <div className="loading-success-container">
        <img src={logoCheck} alt="checked logo" />
        <div className="loading-text">You are all set with Uthrive Premium</div>
      </div>
      <div className="loading-success-backdrop" />
    </>
  );
};
