import { ReactNode } from 'react';
import './style.scss';

type props = {
  children: ReactNode | string;
  className?: string | undefined;
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
};
export const CustomButton = ({ children, className = '', onClick, disabled = false, id, type }: props) => {
  return (
    <button id={id} onClick={onClick} className={`custom-button ${className}`} disabled={disabled} type={type}>
      {children}
    </button>
  );
};
