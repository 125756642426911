import React from 'react';
import { ModalCustom } from 'components/ModalCustom';

import './style.scss';
import { Plan, Subscription, UserSubscriptionConfig } from 'types';
import { CustomButton } from 'components/Button';

type props = {
  isOpen: boolean;
  toggle: () => void;
  plans: Plan[];
  setSelectedPlan: (id: string) => void;
  selectedPlan: string;
  isTrialAvailable: boolean;
  userSubscriptionConfig: UserSubscriptionConfig | null;
  handleSubmit: () => void;
  activeSubscription: Subscription | null;
  isUpdateModal?: boolean;
};

export const ModalUpdatePlan = ({
  isOpen = false,
  toggle,
  plans,
  selectedPlan,
  setSelectedPlan,
  isTrialAvailable = false,
  userSubscriptionConfig,
  handleSubmit,
  activeSubscription,
  isUpdateModal = false
}: props) => {
  const onCloseModal = () => {
    toggle();
  };

  const currentPlan = plans?.find((p) => p.id === selectedPlan);

  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        className="modal-lg modal-update-plan"
        toggle={onCloseModal}
        onClose={onCloseModal}
        isSupportClose>
        <div className="update-plan-container">
          <div className="mb-5 pb-4 mt-3">
            <div className="update-plan-title">
              {isUpdateModal ? (
                'Select a Subscription Plan'
              ) : (
                <>
                  {`Special Offer: We're giving ${userSubscriptionConfig?.trialPeriod + '-' + userSubscriptionConfig?.trialPeriodTimeUnit} Trial for only`}{' '}
                  <span>${userSubscriptionConfig?.trialFee || 0}</span>!
                </>
              )}
            </div>
            {!isUpdateModal && isTrialAvailable && (
              <div className="update-plan-subtitle">{`After ${userSubscriptionConfig?.trialPeriod + '-' + userSubscriptionConfig?.trialPeriodTimeUnit}, you will be subscribed to the selected plan. Cancel any time.`}</div>
            )}
          </div>
          <div className="update-plan-plans">
            {plans.map((plan) => (
              <div
                className={plan?.id === selectedPlan ? 'plan-item-container selected' : 'plan-item-container'}
                onClick={() => setSelectedPlan(plan?.id)}
                key={plan.id}>
                <div className="plan-name">
                  {plan.productName?.replace(' Plan', '')}
                  {plan.prices[0].pricePercentageSaving && (
                    <div className="discount-percentage">{plan.prices[0].pricePercentageSaving}% off</div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="plan-details">
            {currentPlan?.prices[0].originPrice && (
              <span
                className={`plan-original-price ${currentPlan?.prices[0].priceRecurringInterval === 'month' ? 'isMonth' : ''}`}>{`$${currentPlan?.prices[0].originPrice} `}</span>
            )}
            {/* {currentPlan?.prices[0].priceRecurringInterval !== 'month' && (
              <span className="discount-price">${currentPlan?.prices[0].priceUnitAmountDecimal}</span>
            )} */}
            <span className="discount-price">${currentPlan?.prices[0].priceUnitAmountDecimal}</span>
            <span className="plan-unit"> per {currentPlan?.prices[0].priceRecurringInterval}</span>
          </div>

          <div className="update-plan-btn-container">
            <div className="update-plan-text-promote">
              Get <span>$400+</span> Automatic, maximum savings on your cards. Feel confident on your purchases.
            </div>
            <CustomButton
              className="update-plan-submit-button"
              onClick={handleSubmit}
              disabled={
                isUpdateModal ? activeSubscription?.userSubscriptionPrice?.priceProductId === selectedPlan : false
              }>
              {isUpdateModal ? 'Update' : 'Continue'}
            </CustomButton>
          </div>
        </div>
      </ModalCustom>
    </>
  );
};
