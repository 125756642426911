import { cardRecordType } from 'types';
import './style.scss';
import { cardColors } from 'const';

type props = {
  data: cardRecordType;
  onClick?: (id: number) => void;
  isDetails?: boolean;
  footerBackgroundColor?: string;
};

export const FeaturedCard = ({ data, onClick, isDetails = false, footerBackgroundColor = cardColors[1] }: props) => {
  const value = Number(data?.welcomeBonusReward?.welcomeBonusDisplayValue);
  const formattedValue = new Intl.NumberFormat('en-US').format(value);
  const currencyValue = data?.welcomeBonusReward?.welcomeBonusCurrencyDisplayValue;
  const bonusFormat = currencyValue === '$' ? `$${formattedValue}` : `${formattedValue} ${currencyValue}`;

  return (
    <div className="featured-card">
      <div className="featured-card-tag">{`${data?.recommendedCardHeadline}`}</div>
      <div
        className="featured-card-container"
        onClick={!isDetails && onClick ? () => onClick(data?.mappedCardId) : undefined}>
        <div className="featured-card--name">{data?.cardNameFormat}</div>
        <div className="featured-card--rewards">
          {data.mappedCategoryRewards?.map((rw, idx) => {
            return (
              <div key={idx} className="featured-card--reward">
                <img
                  src={rw?.mappedCategoryImage}
                  className="featured-card--reward-img"
                  alt="reward"
                  title={rw?.mappedCategory}
                />
                <div className="featured-card--reward-value">{rw?.rewardValue}</div>
              </div>
            );
          })}
        </div>
        <div className="featured-card-bonus" style={{ backgroundColor: footerBackgroundColor }}>
          <div
            className={`featured-card-bonus-container ${data?.welcomeBonusReward && data?.welcomeBonusReward?.welcomeBonusQualSpend ? 'rewardsBox' : 'specialOffer'}`}>
            {data?.welcomeBonusReward && data?.welcomeBonusReward?.welcomeBonusQualSpend ? (
              <>
                <div className="featured-card-bonus--bonus">
                  <div className="featured-card-bonus--title">Bonus</div>
                  <div className="featured-card-bonus--value">{bonusFormat}</div>
                </div>
                <div className="divider" />
                <div className="featured-card-bonus--bonus">
                  <div className="featured-card-bonus--title">Spend</div>
                  <div className="featured-card-bonus--value">{`${data?.welcomeBonusReward?.welcomeBonusQualSpend} in ${data?.welcomeBonusReward?.welcomeBonusQualPeriodInMonths}`}</div>
                </div>
                <div className="divider" />
                <div className="featured-card-bonus--bonus">
                  <div className="featured-card-bonus--title">Bonus Value</div>
                  <div className="featured-card-bonus--value">{data?.thirdOptionalFieldDisplayValue}</div>
                </div>
              </>
            ) : (
              <div className="featured-card-bonus-specialOffer">{data?.specialOffer}</div>
            )}
          </div>
          {/* {!isDetails && <img src={nextIcon} alt="icon" className="featured-card-bonus--icon" />} */}
        </div>
      </div>
      {!isDetails && (
        <div className="featured-card-bonus--tellMeMore">
          <button
            className="featured-card-bonus--button"
            onClick={!isDetails && onClick ? () => onClick(data?.mappedCardId) : undefined}>
            Tell me more
          </button>
        </div>
      )}
    </div>
  );
};
