import { ModalCustom } from 'components/ModalCustom';
import { SavingOfferType } from 'types';
import './style.scss';
import { fixMoney } from 'utils';
import moment from 'moment';

type props = {
  data: SavingOfferType | null;
  isOpen: boolean;
  onClose: () => void;
};

export const ModalSavingOfferDetail = ({ data, isOpen = false, onClose }: props) => {
  if (!data) return null;
  return (
    <ModalCustom
      isOpen={isOpen}
      className="custom-modal-saving-offer-details"
      toggle={onClose}
      onClose={onClose}
      isSupportClose>
      <div className="saving-offer-detail-container">
        <div className="saving-offer-detail-header">
          <img src={data?.offerMerchantLogoUrl} alt="merchant" />
          <div className="saving-offer-detail-header--info-container">
            <div className="saving-offer-detail-header--info-name">{data?.offerMerchant}</div>
            <div className="saving-offer-detail-header--info-amount">Spent: ${fixMoney(data.userOfferSaleAmount)}</div>
            <div className="saving-offer-detail-header--info-date">
              Date: {moment(data.userOfferCommissionTransactionDate).format('ddd MM/YY')}
            </div>
          </div>
        </div>
        <div className="saving-offer-detail--cashback">Up to {data.offerMerchantRateAmount}% Back</div>
        <div className="saving-offer-detail--offer-savings">
          <div className="saving-offer-detail--offer-savings-title">Offer Savings</div>
          <div className="saving-offer-detail--offer-savings-amount">${fixMoney(data.endUserCommissionAmount)}</div>
        </div>
        <div className="saving-offer-detail--offer-status">
          <div className="saving-offer-detail--offer-status-title">Status</div>
          <div className="saving-offer-detail--offer-status-value">{data.userOfferCommissionStatus}</div>
          <div className="saving-offer-detail--offer-status-note">
            <div>Pending: Merchant confirmation awaited</div>
            <div>Ready: Savings ready to be redeemed</div>
            <div>Processing: Payout in progress</div>
            <div>Paid: Payout complete</div>
          </div>
        </div>
      </div>
    </ModalCustom>
  );
};
