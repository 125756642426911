import './style.scss';

type props = {
  name: string;
  handleClick: () => void;
  isFiltered?: boolean;
};

export const SeeAllItem = ({ name, handleClick, isFiltered = false }: props) => {
  return (
    <div className="see-all-offer-item">
      <button className="see-all-offer-item-name" onClick={handleClick}>
        {/* <div className="name">{!isFiltered ? `See All ${name} Offers` : name}</div> */}
        <div className="name">{!isFiltered ? `See All` : name}</div>
      </button>
    </div>
  );
};
