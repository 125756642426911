import Slider from 'react-slick';
import './style.scss';
import { RecommendedCard } from 'components/RecommendedCard';
import back from 'assets/images/back.png';
import next from 'assets/images/next.png';
import { useMemo, useRef, useState } from 'react';
import { cardRecordType } from 'types';
import { get, includes } from 'lodash';
import { useAppStore } from 'store';
import { formatMoney } from 'utils';
import nextIcon from 'assets/images/next.png';
import { useNavigate } from 'react-router-dom';

type props = {
  handleClickSeeAll: () => void;
  data: cardRecordType[];
  handleClickItem: (id: number) => void;
};

type propsArrow = {
  onClick: () => void;
  currentIdx: number;
  numberOfSlides: number;
};

function NextArrow(props: propsArrow) {
  const { onClick, currentIdx, numberOfSlides } = props;
  const isDisable = currentIdx === numberOfSlides - 1;
  return (
    <div className={`slider-custom-arrow ${isDisable ? 'disabled' : ''}`} onClick={onClick}>
      <img src={next} alt="arrow" />
    </div>
  );
}

function PrevArrow(props: propsArrow) {
  const { onClick, currentIdx } = props;
  const isDisable = currentIdx === 0;
  return (
    <div className={`slider-custom-arrow ${isDisable ? 'disabled' : ''}`} onClick={onClick}>
      <img src={back} alt="arrow" />
    </div>
  );
}

const settings = {
  centerMode: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

export const RecommendedCards = ({ handleClickSeeAll, data, handleClickItem }: props) => {
  const navigate = useNavigate();

  const { setRecommendedCardHeadline } = useAppStore();

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef<Slider>(null);

  const handleBack = () => {
    sliderRef.current?.slickPrev();
  };
  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handleChangeSlide = (idx: number) => {
    const currentHeadline = data[idx]?.recommendedCardHeadline || '';
    setCurrentSlide(idx);
    setRecommendedCardHeadline(currentHeadline);
  };

  const handleClickSurvey = () => {
    navigate('/user-survey');
  };

  const getTitle = useMemo(() => {
    const currentSource = data[currentSlide]?.source;
    switch (currentSource) {
      case 'MISSED':
        return 'Best cards based on your spend habits';
      case 'FEATURED':
        return 'Cards popular with others like you';
      default:
        return null;
    }
  }, [currentSlide, data]);

  const getStyleView = useMemo(() => {
    const currentSource = data[currentSlide]?.source;
    switch (currentSource) {
      case 'MISSED':
        return {};
      case 'FEATURED':
        return {
          backgroundColor: '#0C8F38'
        };
      default:
        return {
          backgroundColor: 'transparent'
        };
    }
  }, [currentSlide, data]);

  const getStyleText = useMemo(() => {
    const currentSource = data[currentSlide]?.source;
    switch (currentSource) {
      case 'MISSED':
        return {};
      case 'FEATURED':
        return { color: 'white' };
      default:
        return {};
    }
  }, [currentSlide, data]);

  return (
    <div className="recommended-cards-container">
      <div className="recommended-cards-slider-container">
        <PrevArrow onClick={handleBack} currentIdx={currentSlide} numberOfSlides={data?.length + 1} />
        <div className="recommended-cards-slider-wrapper">
          <div className="recommended-cards-btn-container">
            <div className="recommended-cards-tag" style={{ ...getStyleView, ...getStyleText }}>
              {getTitle}
            </div>
            <div className="recommended-cards-btn-seeall" onClick={handleClickSeeAll}>
              See all
            </div>
          </div>
          <Slider {...settings} ref={sliderRef} className="recommended-cards-slider" afterChange={handleChangeSlide}>
            {data?.map((e, idx) => {
              const isBonus = !!includes(
                ['mi', 'pts'],
                get(e, 'welcomeBonusReward.welcomeBonusCurrencyDisplayValue', '')
              );

              const bonusData =
                e?.welcomeBonusReward?.welcomeBonusCurrencyDisplayValue === '$'
                  ? `$${formatMoney(e?.welcomeBonusReward?.welcomeBonusDisplayValue)}`
                  : `${formatMoney(e?.welcomeBonusReward?.welcomeBonusDisplayValue)} ${e?.welcomeBonusReward?.welcomeBonusCurrencyDisplayValue}`;

              return (
                <RecommendedCard
                  name={e.cardNameFormat}
                  key={idx}
                  bonus={bonusData}
                  spend={`${e?.welcomeBonusReward?.welcomeBonusQualSpend} in ${e?.welcomeBonusReward?.welcomeBonusQualPeriodInMonths}`}
                  rewards={e?.thirdOptionalFieldDisplayValue}
                  hasBonus={!!e.welcomeBonusReward && !!e?.welcomeBonusReward?.welcomeBonusQualSpend}
                  specialOffer={e.specialOffer}
                  handleClickItem={() => handleClickItem(e.mappedCardId)}
                  isBonus={isBonus}
                  hasTermApply={e.hasTermApply}
                />
              );
            })}
            <div className="recommended-card-container" onClick={handleClickSurvey}>
              <div className="recommended-card-survey-title">
                Not thrilled with recommended cards? <span>Update Preferences.</span>
              </div>
              <div className="recommended-card-content">
                <div className="recommended-card-reward-container" />
                <img src={nextIcon} alt="next" className="recommended-card-icon" />
              </div>
            </div>
          </Slider>
        </div>
        <NextArrow onClick={handleNext} currentIdx={currentSlide} numberOfSlides={data?.length + 1} />
      </div>
    </div>
  );
};
