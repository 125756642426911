export const getLinkDownloadExtensionByBrowser = () => {
  const test = function (regexp: RegExp) {
    return regexp.test(window.navigator.userAgent);
  };
  let browser = 'Chrome';
  switch (true) {
    case test(/edg/i):
      browser = 'edge';
      break;
    case test(/firefox|fxios/i):
      browser = 'firefox';
      break;
    case test(/chrome|chromium|crios/i):
      browser = 'chrome';
      break;
    case test(/safari/i):
      browser = 'safari';
      break;
    default:
      break;
  }

  return browser;
};
