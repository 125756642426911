import emailOk from 'assets/images/email-ok.png';
import './styles.scss';
import { FormGroup, Input, Label } from 'reactstrap';
import { CustomButton, ModalCustom } from 'components';
import { useEffect, useState } from 'react';
import { useAppStore } from 'store';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { feedbackEarnedReward, getEarnedRewardDetail, getEarnedFeedbackByTransactionID } from 'services';
import { EarnedRewardDetailType, FeedbackEarnedRewardParamsType } from 'types';
import { fixMoney } from 'utils';
import chevLeft from 'assets/images/chevron-left.svg';
import moment from 'moment';

const ThankYouForm = ({ onClickHandler }: { onClickHandler: () => void }) => {
  return (
    <>
      <div className="missed-issue-thankYou">
        <h2>Thank you, we received your message</h2>
        <img className="icon" src={emailOk} alt="Email Ok" />
        <p>
          <p>Thanks for your inputs. We will review to make necessary changes and get back to you as soon as we can.</p>
        </p>
      </div>
      <div className="missed-issue-submitBox">
        <CustomButton type="submit" onClick={onClickHandler}>
          Back
        </CustomButton>
      </div>
    </>
  );
};

export const EarnedReportIssue = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { cardId, transactionId } = useParams();

  const { setAppLoading } = useAppStore();

  const isReported = searchParams.get('isReported') || '';
  const reported = isReported ? JSON.parse(isReported) : false;

  const [earnedRewardsData, setEarnedRewardsData] = useState<EarnedRewardDetailType | null>(null);
  const [value, setValue] = useState('');

  const [wrongReasons, setWrongReasons] = useState<string[]>([]);
  const [send, setSend] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCat, setOpenModalCat] = useState(false);
  const [openModalCal, setOpenModalCal] = useState(false);
  const [report, setReport] = useState(reported);

  const transactionSelected =
    earnedRewardsData?.mostEarnedTransactions?.find((t) => t.id === transactionId) ||
    earnedRewardsData?.mostRecentTransactions?.find((t) => t.id === transactionId);

  useEffect(() => {
    if (!cardId || !transactionId) {
      navigate('/', { replace: true });
      return;
    }
    fetchData();
  }, [cardId, transactionId]);

  const fetchData = async () => {
    try {
      if (!cardId || !transactionId) return;
      setAppLoading(true);
      const params = {};

      if (isReported) {
        const resp = await Promise.all([
          getEarnedRewardDetail(params, cardId),
          getEarnedFeedbackByTransactionID(transactionId)
        ]);
        setEarnedRewardsData(resp[0]?.data);
        setWrongReasons(resp[1]?.data?.wrongCategorizationReasons || []);
        setValue(resp[1]?.data?.userComment || '');
      } else {
        const resp = await getEarnedRewardDetail(params, cardId);
        setEarnedRewardsData(resp.data);
      }

      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleChangeCheckBox = (val: string) => {
    setReport(false);
    const temp = wrongReasons?.find((e) => e === val);
    if (temp) {
      const filtered = wrongReasons?.filter((e) => e !== val);
      setWrongReasons(filtered);
    } else {
      setWrongReasons([...wrongReasons, val]);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!transactionSelected) {
        return;
      }
      setAppLoading(true);
      const params: FeedbackEarnedRewardParamsType = {
        txnId: transactionId || '',
        rewardType: 'EARNED',
        wrongCategorizationReasons: wrongReasons,
        rewardAmt: transactionSelected?.rewardAmount || 0,
        userComment: value?.trim()
      };
      await feedbackEarnedReward(params);
      handleSendBtn();
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleSendBtn = () => {
    setWrongReasons([]);
    setValue('');
    setSend((prevState) => !prevState);
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const isDisableBtn =
    (wrongReasons?.length === 0 && value?.trim()?.length < 5) || (!!value?.trim() && value?.trim()?.length < 5);

  return (
    <>
      <div className="missed-issue">
        <button className="missed-issue-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        {send !== true ? (
          <>
            <div className="infobox">
              <h1 className="infobox-header-title">
                What&#39;s wrong with this transaction?{' '}
                <span className="missed-issue-infoIcon" onClick={() => setOpenModal(true)}></span>
              </h1>
              <div className="infobox-label">
                <span>Transaction</span>
                <span>Earned rewards</span>
              </div>
              <div className="transaction-item">
                <div className="transaction-card">
                  <img style={{ width: '100px' }} src={earnedRewardsData?.account?.accountImageUrl} alt="card" />
                  <span className="name">{`${earnedRewardsData?.account?.name}${earnedRewardsData?.account?.accountMask ? ` - ${earnedRewardsData?.account?.accountMask}` : ''}`}</span>
                </div>
                <div className="transaction-infobox">
                  <div className="info">
                    <span className="info-name">{transactionSelected?.name}</span>
                    <span className="info-date">Date: {moment(transactionSelected?.date)?.format('MM/DD/YYYY')}</span>
                    <span className="info-spent">Spent: ${fixMoney(transactionSelected?.spentAmount || 0)}</span>
                  </div>
                  <div className="reward">
                    <span>${transactionSelected?.rewardAmount || 0}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="reportissue">
              <FormGroup check>
                <Input
                  className="reportissue-checkbox"
                  type="checkbox"
                  checked={wrongReasons?.includes('WRONG_CATEGORY')}
                  onChange={() => handleChangeCheckBox('WRONG_CATEGORY')}
                />
                <Label className="reportissue-checkbox-label" check>
                  Wrong category. <span className="reportissue-infoIcon" onClick={() => setOpenModalCat(true)}></span>
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  className="reportissue-checkbox"
                  placeholder="Anything else to add.."
                  type="checkbox"
                  checked={wrongReasons?.includes('WRONG_REWARD_CALCULATION')}
                  onChange={() => handleChangeCheckBox('WRONG_REWARD_CALCULATION')}
                />
                <Label className="reportissue-checkbox-label" check>
                  Wrong reward calculation.{' '}
                  <span className="reportissue-infoIcon" onClick={() => setOpenModalCal(true)}></span>
                </Label>
              </FormGroup>
              <FormGroup>
                <Input
                  className="reportissue-textarea"
                  id="exampleText"
                  name="text"
                  type="textarea"
                  maxLength={4000}
                  value={value}
                  onChange={(e) => {
                    setReport(false);
                    setValue(e?.target?.value);
                  }}
                />
              </FormGroup>
              <div className="reportissue-submitBox">
                <CustomButton type="submit" onClick={handleSubmit} disabled={isDisableBtn}>
                  {report ? 'Submitted' : 'Submit'}
                </CustomButton>
              </div>
            </div>
          </>
        ) : (
          <ThankYouForm onClickHandler={handleSendBtn} />
        )}
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title"> Report issue about a transaction</div>
          <div>
            You can now report issue on any of your transactions.
            <br />
            <br />
            Uthrive receives your transactions from the bank and sometimes it&#39;s received with incorrect category
            information for example, a restaurant purchase may come in as Gas station.
            <br />
            <br />
            Uthrive has built several refinements to prevent inaccurate category and/or rewards calculation but some
            mistakes may still remain.
            <br />
            <br />
            You can report any inaccuracies, this will be looked into for appropriate updates, so you can stay confident
            about maximizing rewards and savings.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModal(!openModal)}>Back</CustomButton>
      </ModalCustom>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModalCat}
        toggle={() => setOpenModalCat(!openModalCat)}
        onClose={() => setOpenModalCat(!openModalCat)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Wrong Category</div>
          <div>
            You can now report a transaction for appearing in a wrong category.
            <br />
            <br />
            An inaccurate category can result in incorrect rewards calculation and recommendation.
            <br />
            <br />
            Once you report any transaction appearing in wrong category, it will be investigated for appropriate
            updates, and you will be promptly informed.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModalCat(!openModalCat)}>Back</CustomButton>
      </ModalCustom>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModalCal}
        toggle={() => setOpenModalCal(!openModalCal)}
        onClose={() => setOpenModalCal(!openModalCal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Wrong Rewards Calculation</div>
          <div>
            You can now report a transaction for wrong rewards calculation. <br />
            <br />
            An inaccurate earned or missed rewards calculation can result in incorrect recommendation. <br />
            <br />
            Once you report any transaction appearing to have wrong rewards calculation, it will be investigated for
            appropriate updates, and you will be promptly informed.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModalCal(!openModalCal)}>Back</CustomButton>
      </ModalCustom>
    </>
  );
};
