import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ModalCustom } from 'components/ModalCustom';
import heartIcon from 'assets/images/heart.png';
import estimateIcon from 'assets/images/estimate.jpg';
import tickIcon from 'assets/images/tick.jpg';
import heartFilled from 'assets/images/heart-filled.png';
import chevLeft from 'assets/images/chevron-left.svg';
import './style.scss';
import { IBankOffer, IOfferMerchantDetails } from 'types';
import { getRewardValue } from 'utils';
import { CustomButton } from 'components/Button';
import { getOffersMerchantDetails, pinMerchant, unPinMerchant } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { ScrollToBottom } from 'components/ScrollToBottom';
import { get } from 'lodash';
import { PopoverDownloadApp } from 'components';
import checkIcon from 'assets/images/green-check-icon.svg';
// import checkIcon from 'assets/images/uthrive-icon-success.png';

const OfferDetailsRate = ({ kind, amount }: { kind: string; amount: string }) => {
  const [showHowItWork, setShowHowItWork] = useState(false);
  return (
    <div className="offer-details-rate-container">
      <div className="offer-details-rate-title">
        Get additional <span>Up to {getRewardValue(kind || '', amount || '')}</span>. Click ‘Get Offers’
      </div>
      <div className="offer-details-rate-footer">
        <div className="get-offer" onClick={() => setShowHowItWork(!showHowItWork)}>
          How it works?
        </div>
        {showHowItWork && (
          <div className="btn-close-details" onClick={() => setShowHowItWork(!showHowItWork)}>
            X
          </div>
        )}
      </div>
      {showHowItWork && (
        <div className="how-it-work-text">
          Simply click on &apos;Get Offer&apos; to visit merchant site and make purchases. You will get cash back from
          Uthrive on qualifying purchase amount, which you can redeem into your checking or savings account via PayPal.
          You will earn this, in addition to rewards or cashback offered by your credit card.
        </div>
      )}
    </div>
  );
};

const OfferCoupon = ({
  offerCouponDescription,
  offerCouponCode,
  isAutomaticallyApplied
}: {
  offerCouponDescription: string;
  offerCouponCode: string;
  isAutomaticallyApplied: boolean;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isCopied]);

  const handleCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(offerCouponCode);
  };

  return (
    <div className="Offer-coupon-container mt-2 mb-2">
      <div className="Offer-coupon-description">{offerCouponDescription}</div>
      <br />
      {!isAutomaticallyApplied ? (
        <>
          <div className="Offer-coupon-code">
            Click to visit merchant site and apply coupon <span>{offerCouponCode}</span>.
          </div>
          {isCopied ? (
            <div className="offer-coupon-btn-link">Coupon copied</div>
          ) : (
            <div className="offer-coupon-btn-link" onClick={handleCopy}>
              Click to copy coupon
            </div>
          )}
        </>
      ) : (
        <p style={{ fontSize: '14px' }}>
          Tap &#39;Get Offers&#39; to visit merchant site & automatically apply coupon.
        </p>
      )}
    </div>
  );
};

const PremiumOfferActivated = ({ bankOffer, mappedMerchant }: { bankOffer: IBankOffer; mappedMerchant: string }) => {
  const [openTC, setOpenTC] = useState('');
  const [isMore, setIsMore] = useState(false);

  const cardMark = get(bankOffer, 'accountMask', '')
    ? `${get(bankOffer, 'cardNameShort', '')} ${get(bankOffer, 'accountSubtype', '')}-${get(bankOffer, 'accountMask', '') || ''}`
    : get(bankOffer, 'cardNameFull', '');

  // const isMine = get(bankOffer, 'isMine', false);
  const potentialRewardText = get(bankOffer, 'potentialRewardText');
  const offerDetails = get(bankOffer, 'offerDetails');
  const earnOfferLang = get(bankOffer, 'earnOfferLang');

  return (
    <>
      <div className="premium-offer-activated-container">
        <div className="premium-offer-activated-card">
          <img src={bankOffer.cardImageUrl} alt="card" />
          <div className="premium-offer-activated-card-info">{cardMark}</div>
        </div>
        <div className="premium-offer-activated-lang">
          {potentialRewardText && (
            <div className="offer-reward">
              Earn <span className="offer-reward-text">{potentialRewardText}</span> at {`${mappedMerchant}`}
            </div>
          )}
        </div>
        <div className="line-divider" />

        <div className="offer-details-premium">
          <div className="offer-details-premium-icon-container">
            <img src={tickIcon} alt="premium" className="icon-check" />
          </div>
          <div className="offer-details-premium-text">Premium Offer Activated</div>
        </div>
        {earnOfferLang && (
          <>
            <div className="offer-reward">{earnOfferLang}</div>
            <div className="premium-offer-activated-tc" onClick={() => setOpenTC(bankOffer?.offerDescription)}>
              T&C Apply
            </div>
          </>
        )}
        {!!offerDetails?.length &&
          offerDetails?.map((e) => {
            return (
              <>
                <div className="offer-reward fw-bold">{e.earnOfferLang}</div>
                <div className="premium-offer-activated-tc" onClick={() => setOpenTC(e.offerDescription)}>
                  T&C Apply
                </div>
              </>
            );
          })}
      </div>
      <ModalCustom
        isOpen={!!openTC}
        className="custom-modal-offer-tc"
        toggle={() => {
          setOpenTC('');
          setIsMore(false);
        }}
        onClose={() => {
          setOpenTC('');
          setIsMore(false);
        }}
        isSupportClose>
        <div className="offerDescription-container">
          <div className="offerDescription--header">
            <div>
              <span className="active-check">
                <img src={checkIcon} alt="chk icon" />
                Offer Activated
              </span>
            </div>
            <div className="offerDescription--merchantName">{mappedMerchant}</div>
          </div>
          <div className={isMore ? 'read-more' : 'no-read-more'}>{openTC || bankOffer?.offerDescription}</div>
          {!isMore && (
            <div
              className="offerDescription--read-more"
              onClick={() => {
                setIsMore(!isMore);
              }}>
              Read more
            </div>
          )}
        </div>
      </ModalCustom>
    </>
  );
};

export const OfferDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const offerName = params?.offerName || '';

  const { setAppLoading, appLoading } = useAppStore();
  const { user } = useAuthStore();

  const [openTC, setOpenTC] = useState('');
  const [isMore, setIsMore] = useState(false);

  const [offerDetailsData, setOfferDetailsData] = useState<IOfferMerchantDetails | null>(null);
  const [isPinned, setIsPinned] = useState(offerDetailsData?.merchant?.isFavoriteMerchant === 'Y');
  const [couponIdx, setCouponIdx] = useState(1);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const dataOfferCoupon = offerDetailsData?.merchant.offerCoupons || [];
  const detailRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchOffer();
  }, [offerName]);

  const fetchOffer = async () => {
    try {
      setAppLoading(true);
      const params = { merchantName: offerName };
      const resp = await getOffersMerchantDetails(params);
      setOfferDetailsData(resp);
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  useEffect(() => {
    setIsPinned(offerDetailsData?.merchant?.isFavoriteMerchant === 'Y');
  }, [offerDetailsData]);

  const handlePinMerchant = async () => {
    if (!offerDetailsData?.merchant.mappedMerchant) return;
    try {
      setAppLoading(true);
      const realPromise = isPinned ? unPinMerchant : pinMerchant;
      await realPromise(offerDetailsData?.merchant.mappedMerchant);
      setAppLoading(false);
      setIsPinned(!isPinned);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleClickGetOffer = () => {
    window.open(offerDetailsData?.merchant?.offerMerchantAffiliateUrl, '_blank');
  };

  const handleEstimateRewards = () => {
    if (offerDetailsData?.merchant.mappedMerchant) {
      navigate(`/cards/compare-rewards/${offerDetailsData?.merchant.mappedMerchant}`);
    }
  };

  const handleClickMoreCoupon = () => {
    if (!dataOfferCoupon || couponIdx >= dataOfferCoupon?.length) return;
    if (couponIdx + 5 >= dataOfferCoupon?.length) {
      setCouponIdx(dataOfferCoupon?.length);
    } else {
      setCouponIdx(couponIdx + 5);
    }
    setCouponIdx(couponIdx + 5);
  };

  const handleBackBtn = () => {
    navigate('/offers');
  };

  const checkScrollbarVisibility = () => {
    if (!detailRef?.current) return false;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    setIsScrollbarVisible(documentHeight > windowHeight + 80);
  };

  useEffect(() => {
    checkScrollbarVisibility();
    // window.addEventListener('resize', checkScrollbarVisibility);
    // return () => {
    //   window.removeEventListener('resize', checkScrollbarVisibility);
    // };
  });

  const dataCouponPlit = useMemo(() => {
    const temp = [...dataOfferCoupon] || [];
    const temp2 = temp?.splice(0, couponIdx) || [];
    return [...temp2];
  }, [couponIdx, dataOfferCoupon]);

  const haveLinkCard = user?.hasLinkedPlaid || user?.inactiveUserHistory || user?.hasManualCard;
  const checkMappedMerchant =
    get(offerDetailsData, 'merchant.mappedMerchant') && get(offerDetailsData, 'bestCardForMerchant.potentialRewardText')
      ? ` ${get(offerDetailsData, 'merchant.mappedMerchant')}`
      : get(offerDetailsData, 'merchant.offerMerchant')
        ? ` ${get(offerDetailsData, 'merchant.offerMerchant')}`
        : '';

  const checkShowCompareReward =
    !!get(offerDetailsData, 'bestCardForMerchant.mappedCardId') || !!get(offerDetailsData, 'bankOffers', [])?.length;
  const checkShowBtnGetOffers = !!get(offerDetailsData, 'merchant.offerMerchantAffiliateUrl');

  const isNoOffer =
    (!offerDetailsData?.bestCardForMerchant?.bankOffers ||
      offerDetailsData?.bestCardForMerchant?.bankOffers.length === 0) &&
    !offerDetailsData?.merchant.offerMerchantRate &&
    (!dataOfferCoupon || dataOfferCoupon.length === 0) &&
    (!offerDetailsData?.bankOffers || offerDetailsData?.bankOffers.length === 0);

  return (
    <>
      {!appLoading ? (
        <>
          <div className="offer-details-page-container">
            <div ref={detailRef} className="offer-details-body">
              <div className="offer-details-titleBox">
                <button className="offer-details-btn-back" onClick={handleBackBtn}>
                  <img src={chevLeft} alt="chevron-left" />
                  Back
                </button>
                <div className="offer-details--merchant">
                  <img
                    src={offerDetailsData?.merchant?.merchantImgUrl || offerDetailsData?.merchant?.categoryImgUrl}
                    alt="merchant"
                  />
                  <div>{offerDetailsData?.merchant?.offerMerchant}</div>
                </div>
              </div>

              {!!checkMappedMerchant && (
                <div className="offer-details-best-card-for">{`Your best card for ${offerDetailsData?.merchant?.mappedMerchant || offerDetailsData?.merchant?.offerMerchant}`}</div>
              )}

              {!offerDetailsData?.merchant?.mappedMerchant ? (
                <div>We are having trouble in finding the best card for this merchant. Please try later.</div>
              ) : haveLinkCard ? (
                !offerDetailsData?.bestCardForMerchant ? (
                  <>
                    <div>
                      None of your existing cards give any rewards on this merchant. Link your other credit or debit
                      cards to view the best card.
                    </div>
                    <div
                      className="offer-btn-outline"
                      id="link-card-btn"
                      onClick={() => setIsOpenPopover(!isOpenPopover)}>
                      Link your cards to maximize rewards
                    </div>
                    <PopoverDownloadApp
                      isOpen={isOpenPopover}
                      handleDone={() => setIsOpenPopover(false)}
                      target="link-card-btn"
                      title="Link your cards on Uthrive App"
                      placement="bottom"
                    />
                  </>
                ) : (
                  <>
                    <div className="offers-details-card-container">
                      <img
                        src={offerDetailsData?.bestCardForMerchant.cardImageUrl}
                        alt="card"
                        className="offer-card-img"
                      />
                      <div className="offer-card-text">{`${offerDetailsData?.bestCardForMerchant.cardNameShort} ${offerDetailsData?.bestCardForMerchant?.accountSubtype} ${offerDetailsData?.bestCardForMerchant.accountMask ? '- ' + offerDetailsData?.bestCardForMerchant.accountMask : ''}`}</div>
                    </div>
                    <div className="offer-reward">
                      Earn <span className="fw-bold">{offerDetailsData?.bestCardForMerchant?.potentialRewardText}</span>{' '}
                      at {`${offerDetailsData?.merchant.mappedMerchant}`}
                    </div>
                  </>
                )
              ) : (
                <>
                  <div>Link your credit or debit cards to view the best card for this merchant.</div>
                  <div
                    className="offer-btn-outline"
                    id="link-card-btn"
                    onClick={() => setIsOpenPopover(!isOpenPopover)}>
                    Link your cards to maximize rewards
                  </div>
                  <PopoverDownloadApp
                    isOpen={isOpenPopover}
                    handleDone={() => setIsOpenPopover(false)}
                    target="link-card-btn"
                    title="Link your cards on Uthrive App"
                    placement="bottom"
                    modalSize="md"
                  />
                </>
              )}
              <div className="line-divider" />
              {offerDetailsData?.bestCardForMerchant?.bankOffers &&
                offerDetailsData?.bestCardForMerchant?.bankOffers?.length > 0 && (
                  <>
                    <div className="offer-details-premium mb-2">
                      <div className="offer-details-premium-icon-container">
                        <img src={tickIcon} alt="premium" className="icon-check" />
                      </div>
                      <div className="offer-details-premium-text">Premium Offer Activated</div>
                    </div>
                    <div className="offer-details-premium-container mb-4">
                      {offerDetailsData?.bestCardForMerchant?.bankOffers?.map((bo, idx) => {
                        return (
                          <>
                            <div key={idx} className="best-card-bank-offer mb-3">
                              {bo.earnOfferLang}
                            </div>
                            <div className="premium-offer-activated-tc" onClick={() => setOpenTC(bo?.offerDescription)}>
                              T&C Apply
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              {offerDetailsData?.merchant.offerMerchantRate && (
                <>
                  <div className="offer-details-save-more tag-green">+ Save More with This Offer</div>
                  <OfferDetailsRate
                    kind={offerDetailsData?.merchant.offerMerchantRate?.kind || ''}
                    amount={offerDetailsData?.merchant.offerMerchantRate?.amount || ''}
                  />
                </>
              )}
              {dataOfferCoupon && dataOfferCoupon?.length > 0 && (
                <>
                  <div className="offer-details-save-more mt-4">+ Coupons you can use</div>
                  {dataCouponPlit?.map((cp, idx) => {
                    return (
                      <OfferCoupon
                        key={idx}
                        offerCouponCode={cp.offerCouponCode}
                        offerCouponDescription={cp.offerCouponDescription}
                        isAutomaticallyApplied={cp.isAutomaticallyApplied}
                      />
                    );
                  })}
                  {dataOfferCoupon?.length > 1 && couponIdx < dataOfferCoupon?.length && (
                    <div className="offer-details-view-more" onClick={handleClickMoreCoupon}>
                      View more coupons {'>'}
                    </div>
                  )}
                </>
              )}

              {offerDetailsData?.bankOffers && offerDetailsData?.bankOffers?.length > 0 && (
                <>
                  <div className="offer-bank-offer-title mt-3 mb-2">Or you can save with this card.</div>
                  {offerDetailsData?.bankOffers?.map((pbo, idx) => {
                    return (
                      <PremiumOfferActivated
                        key={idx}
                        bankOffer={pbo}
                        mappedMerchant={
                          offerDetailsData?.merchant?.mappedMerchant || offerDetailsData?.merchant?.offerMerchant
                        }
                      />
                    );
                  })}

                  {offerDetailsData?.merchant.offerMerchantRate && (
                    <>
                      <div className="offer-details-save-more tag-green mt-4">+ Save More with This Offer</div>
                      <OfferDetailsRate
                        kind={offerDetailsData?.merchant.offerMerchantRate?.kind || ''}
                        amount={offerDetailsData?.merchant.offerMerchantRate?.amount || ''}
                      />
                    </>
                  )}
                </>
              )}
              {isNoOffer && (
                <div className="offer-details--no-offer-container">
                  <div className="offer-details--no-offer-content">
                    <div className="fw-bold">No offers on this merchant</div>
                    <div>
                      Uthrive couldn&apos;t find extra savings for this merchant, but there are exciting offers at other
                      similar merchants - check it out.
                    </div>
                  </div>
                  <CustomButton className="offer-details--no-offer-btn mt-3" onClick={() => navigate('/offers')}>
                    Search others
                  </CustomButton>
                </div>
              )}
            </div>
            <div className="offer-details-footer-container">
              <div className="offer-details-footer">
                <div className="offer-details-footer-item d-none">
                  <img
                    src={isPinned ? heartFilled : heartIcon}
                    alt="heart"
                    className="offer-details-footer-icon"
                    onClick={handlePinMerchant}
                  />
                  <div className="offer-details-footer--text">Pin Merchant</div>
                </div>
                <div className="offer-details-footer-item d-none">
                  <img
                    src={estimateIcon}
                    alt="heart"
                    className="offer-details-footer-icon"
                    onClick={handleEstimateRewards}
                  />
                  <div className="offer-details-footer--text">Estimate Rewards</div>
                </div>
                {checkShowBtnGetOffers && (
                  <CustomButton className="btn-get-offer" onClick={handleClickGetOffer}>
                    Get Offers
                  </CustomButton>
                )}
                {checkShowCompareReward && (
                  <CustomButton className="btn-compare" onClick={handleEstimateRewards}>
                    Compare Rewards
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
          {isScrollbarVisible && <ScrollToBottom />}
          <ModalCustom
            isOpen={!!openTC}
            className="custom-modal-offer-tc"
            toggle={() => {
              setOpenTC('');
              setIsMore(false);
            }}
            onClose={() => {
              setOpenTC('');
              setIsMore(false);
            }}
            isSupportClose>
            <div className="offerDescription-container">
              <div className="offerDescription--header">
                <div>
                  <span className="active-check">
                    <img src={checkIcon} alt="chk icon" />
                    Offer Activated
                  </span>
                </div>
                <div className="offerDescription--merchantName">
                  {offerDetailsData?.merchant?.mappedMerchant || offerDetailsData?.merchant?.offerMerchant}
                </div>
              </div>
              {/* <div className={isMore ? 'read-more' : 'no-read-more'}>{openTC || bankOffer?.offerDescription}</div> */}
              <div className={isMore ? 'read-more' : 'no-read-more'}>{openTC}</div>
              {!isMore && (
                <div
                  className="offerDescription--read-more"
                  onClick={() => {
                    setIsMore(!isMore);
                  }}>
                  Read more
                </div>
              )}
            </div>
          </ModalCustom>
        </>
      ) : (
        <>
          <div className="custom-spinner-container">
            <p>
              Pulling together Best card and savings offer
              <br />
              one moment please...
            </p>
          </div>
        </>
      )}
    </>
  );
};
