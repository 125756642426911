import nextIcon from 'assets/images/next.png';
import './style.scss';
import { formatMoney } from 'utils';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type props = {
  isYear: boolean;
  spent: number;
  availableRewards: number;
  rewardsSummary: {
    earnedTotal: number;
    missedTotal: number;
  };
};

export const EarnedChart = ({ isYear, spent, availableRewards, rewardsSummary }: props) => {
  const navigate = useNavigate();

  const handleClickMissed = () => {
    navigate('/rewards-missed');
  };

  const handleClickEarned = () => {
    navigate('/rewards-earned');
  };

  const handleClickSpent = () => {
    navigate('spent-by-category');
  };

  const getPercentMissedEarnedValue = useMemo(() => {
    const earnedValue = rewardsSummary?.earnedTotal;
    const missedValue = rewardsSummary?.missedTotal;
    if (!earnedValue || !missedValue) {
      return { earned: 100, missed: 100 };
    }
    if (earnedValue > missedValue) {
      if (earnedValue / missedValue > 1.1) {
        return {
          earned: 100,
          missed: 50
        };
      } else {
        return {
          earned: 50,
          missed: 50
        };
      }
    } else if (earnedValue < missedValue) {
      if (earnedValue / missedValue < 0.9) {
        return {
          missed: 100,
          earned: 50
        };
      } else {
        return {
          earned: 50,
          missed: 50
        };
      }
    } else {
      return {
        missed: 100,
        earned: 100
      };
    }
  }, [rewardsSummary, isYear]);

  return (
    <div className="earned-chart-container">
      <div className="earned-chart-rewards-container d-none">
        {spent !== 0 && (
          <div className="earned-chart-reward">
            <div className="earned-chart-reward--title">Spent</div>
            <div className="earned-chart-reward--value" onClick={handleClickSpent}>
              <div>${formatMoney(spent)}</div>
              <img src={nextIcon} alt="next" />
            </div>
          </div>
        )}
        {availableRewards !== 0 && (
          <div className="earned-chart-reward">
            <div className="earned-chart-reward--title">Available Rewards</div>
            <div className="earned-chart-reward--value">
              <div>${formatMoney(availableRewards)}</div>
              <img src={nextIcon} alt="next" />
            </div>
          </div>
        )}
      </div>
      <div className="earned-charts">
        <div className="earned-chart-content" onClick={handleClickEarned}>
          <div className="earned-chart-value-container">
            <div className="earned-chart-title">Earned</div>
            <div className="earned-chart-row-container">
              <div className="earned-chart-row-background">
                <div className="earned-chart-row-track" style={{ width: `${getPercentMissedEarnedValue?.earned}%` }}>
                  +${formatMoney(rewardsSummary.earnedTotal)}
                </div>
              </div>
            </div>
          </div>
          <img src={nextIcon} alt="next" />
        </div>
        <div className="earned-chart-content" onClick={handleClickMissed}>
          <div className="earned-chart-value-container">
            <div className="earned-chart-title">Missed</div>
            <div className="earned-chart-row-container">
              <div className="earned-chart-row-background">
                <div
                  className="earned-chart-row-track missed"
                  style={{ width: `${getPercentMissedEarnedValue?.missed}%` }}>
                  -${formatMoney(rewardsSummary.missedTotal)}
                </div>
              </div>
            </div>
          </div>
          <img src={nextIcon} alt="next" />
        </div>
      </div>
    </div>
  );
};
