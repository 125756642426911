import { AppFooter } from 'components/AppFooter';
import { AppNavbar } from 'components/Navbar';
import { ReactNode, memo } from 'react';
import './style.scss';
import { useLocation } from 'react-router-dom';

type props = {
  children: ReactNode;
};

export const AppLayout = memo(({ children }: props) => {
  const location = useLocation();

  const isExtensionPage = location?.pathname.includes('/uninstall-extension');
  return (
    <div className="app-layout" style={{ paddingTop: isExtensionPage ? 0 : '97px' }}>
      <AppNavbar />
      {children}
      <AppFooter />
    </div>
  );
});
