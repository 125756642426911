import * as React from 'react';
import previousIco from 'assets/images/back.png';
import './style.scss';

type props = {
  signUpData: {
    email_address?: string;
    phone_number_format?: string;
    phone_number?: string;
  };
  handleBack: () => void;
  isLoading: boolean;
};

export const SignUpStep2Header = (props: props) => {
  const { signUpData, handleBack, isLoading } = props;
  let notice = '';
  if (signUpData?.phone_number_format || signUpData?.phone_number) {
    notice = `Enter the code we texted you on your phone.`;
  } else if (signUpData?.email_address) {
    notice = `Enter the code we sent you at ${signUpData?.email_address}.`;
  }

  return (
    <div className="header-otp-step">
      <br />
      <div className="btn-back" onClick={!isLoading ? handleBack : undefined}>
        <img src={previousIco} className="back-ico" alt="previous-ico" width="25px" height="25px" />
        Back
      </div>
      <div className="title">Enter the 6-digit code.</div>
      <div className="otp-code-title">{notice}</div>
      <br />
    </div>
  );
};
