import React, { useEffect } from 'react';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'store';

export const TermPage = () => {
  const navigate = useNavigate();

  const { setAppLoading } = useAppStore();

  useEffect(() => {
    setAppLoading(true);
  }, []);

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };
  return (
    <div className="policy">
      <div className="policy-container">
        {' '}
        <button className="compare-rewards-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <iframe
          onLoad={() => setTimeout(() => setAppLoading(false), 1500)}
          title="terms and conditions"
          id="idIframe"
          className="term-frame"
          src="https://app.termly.io/document/terms-of-service/8d98ecfd-71bc-474a-901b-973f25723880"
          width="100%"></iframe>
      </div>
    </div>
  );
};
