import { useEffect, useMemo, useRef, useState } from 'react';
import './style.scss';
import { getCardDetails, getCards, reactionCard } from 'services';
import { useAppStore, useCardsStore } from 'store';
import { CustomButton, FeaturedCard, ModalCustom, ScrollToTop } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cardRecordType } from 'types';
import iconLeft from 'assets/images/left-arrow.png';
import iconRight from 'assets/images/right-arrow.png';
import iconLike from 'assets/images/like.png';
import iconDislike from 'assets/images/dislike.png';
import iconShare from 'assets/images/share-white.png';
import arrowRight from 'assets/images/arrow-right.svg';
import chevLeft from 'assets/images/chevron-left.svg';
export const CardDetails = () => {
  const params = useParams<{ cardId: string }>();
  const navigate = useNavigate();

  const id = params?.cardId || '';

  const { setAppLoading } = useAppStore();
  const { featuredCards, setFeaturedCards } = useCardsStore();

  const [cardDetails, setCardDetails] = useState<cardRecordType | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchCards(id);
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyHeaderRef.current) {
        setStickyHeader(window.pageYOffset >= stickyHeaderRef.current?.offsetHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return window.addEventListener('scroll', handleScroll);
  }, [stickyHeaderRef]);

  const fetchCards = async (cardId: string) => {
    if (!cardId) return;
    try {
      setAppLoading(true);
      const reps = await Promise.all([getCardDetails(cardId), getCards()]);
      setCardDetails(reps[0]?.data);
      setFeaturedCards(reps[1]?.data);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
      navigate('/cards', { replace: true });
    }
  };

  const handleClick = () => {
    window.open(cardDetails?.redirectUrl, '_blank');
  };

  const handleLike = async () => {
    try {
      if (!id) return;
      await reactionCard({ cardId: id, reactionType: 'Y' });
      toast.success('Thank for your feedback');
    } catch (err) {
      console.log(err);
    }
  };

  const handleDislike = async () => {
    try {
      if (!id) return;
      await reactionCard({ cardId: id, reactionType: 'N' });
      toast.success('Thank for your feedback');
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewAll = () => {
    navigate('/cards', { replace: true });
  };

  const handleShare = () => {
    if (!cardDetails?.appAffiliateLink) return;
    navigator.clipboard.writeText(cardDetails?.appAffiliateLink);
    toast.success('Copied the link');
  };

  const handlePreCard = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    const idx = featuredCards?.findIndex((card) => card.mappedCardId?.toString() === id);

    if (idx !== -1 && idx !== 0) {
      navigate(`/cards/${featuredCards[idx - 1]?.mappedCardId}`, { replace: true });
    }
  };

  const handleNextCard = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    const idx = featuredCards?.findIndex((card) => card.mappedCardId?.toString() === id);

    if (idx !== -1 && idx < featuredCards?.length - 1) {
      navigate(`/cards/${featuredCards[idx + 1]?.mappedCardId}`, { replace: true });
    }
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const isLastCard = useMemo(() => {
    if (
      !!featuredCards &&
      featuredCards?.length > 0 &&
      id === featuredCards[featuredCards?.length - 1]?.mappedCardId?.toString()
    ) {
      return true;
    }
    return false;
  }, [featuredCards, id]);

  const isFirstCard = useMemo(() => {
    if (!!featuredCards && featuredCards?.length > 0 && id === featuredCards[0]?.mappedCardId?.toString()) {
      return true;
    }
    return false;
  }, [featuredCards, id]);

  if (!cardDetails) return <div className="card-details-container"></div>;
  return (
    <>
      <div className={`sticky-section-animated ${stickyHeader ? 'active' : 'inactive'}`}>
        <div className="sticky-container">
          <div className="sticky-card-info">
            <p className="card__title">
              <b>{cardDetails?.cardNameFormat}</b>
            </p>
            <a href={cardDetails?.redirectUrl} rel="noreferrer" target="_blank">
              {cardDetails?.typeRedirectUrlDisplayValue === 'TEXT_LINK' ? (
                <CustomButton className="card-details-chase" onClick={handleClick}>
                  Tell me more <img className="arrow" src={arrowRight} alt="arrow right" />
                </CustomButton>
              ) : (
                <CustomButton className="card-details-btn" onClick={handleClick}>
                  View How to Apply
                </CustomButton>
              )}
            </a>
          </div>
        </div>
      </div>
      <div className="card-details-container">
        <div className="card-details-body">
          <div className="card-details-cardContainer" ref={stickyHeaderRef}>
            <div className="card-details-contents">
              <button className="card-details-btnBack" onClick={handleBackBtn}>
                <img src={chevLeft} alt="chevron-left" />
                Back
              </button>
              <FeaturedCard data={cardDetails} isDetails />
              {cardDetails?.ratesAndFeesUrl ? (
                <div className="card-details-terms">
                  View{' '}
                  <a href={cardDetails?.ratesAndFeesUrl} target="_blank" rel="noreferrer">
                    rates and fees
                  </a>{' '}
                  of this card.
                </div>
              ) : (
                ''
              )}
              <button className="card-details-btnViewAll" onClick={handleViewAll}>
                View all
              </button>
            </div>
          </div>
          <div>
            <div className="card-details-info-container">
              <div className="card-details-title">Why We Recommend This Card</div>
              <div
                className="card-details-desc"
                dangerouslySetInnerHTML={{
                  __html: cardDetails?.recommendedCardDescription.replace(
                    /\$\d{1,3}(,\d{3})*(\d{3})*/g,
                    '<span class="highlight">$&</span>'
                  )
                }}></div>
              <div className="card-details-cta">
                {cardDetails?.typeRedirectUrlDisplayValue === 'TEXT_LINK' ? (
                  <CustomButton className="card-details-chase" onClick={handleClick}>
                    Tell me more <img className="arrow" src={arrowRight} alt="arrow right" />
                  </CustomButton>
                ) : (
                  <CustomButton className="card-details-btn" onClick={handleClick}>
                    View How to Apply
                  </CustomButton>
                )}
              </div>
              <div className="card-details-terms">
                {cardDetails?.ratesAndFeesUrl ? (
                  <div>
                    View{' '}
                    <a href={cardDetails?.ratesAndFeesUrl} target="_blank" rel="noreferrer">
                      rates and fees
                    </a>{' '}
                    of the {cardDetails?.cardNameFormat}.
                  </div>
                ) : (
                  ''
                )}

                <div className="popup" onClick={() => setOpenModal(true)}>
                  Advertising, Editorial and Terms Apply Disclosure.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-details-footer">
          <div className="card-details-footer-container">
            <div className="card-details-footer-col">
              <div className="card-details-footer--icon-container" onClick={handleLike}>
                <img src={iconLike} alt="icon" />
              </div>
              <div className="card-details-footer--icon-container" onClick={handleDislike}>
                <img src={iconDislike} alt="icon" />
              </div>
              <div className="card-details-footer--icon-container d-none" onClick={handleShare}>
                <img src={iconShare} alt="icon" />
              </div>
            </div>
            <div className="card-details-footer-col">
              <div
                className={`card-details-footer--icon-black-container ${isFirstCard ? 'hidden' : ''}`}
                onClick={handlePreCard}>
                <img src={iconLeft} alt="icon" />
              </div>
              <div
                className={`card-details-footer--icon-black-container ${isLastCard ? 'hidden' : ''}`}
                onClick={handleNextCard}>
                <img src={iconRight} alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <ModalCustom
          className="modal-annual-reward-note"
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
          onClose={() => setOpenModal(!openModal)}>
          <div className="modal-annual-reward-note-container">
            <div className="modal-annual-reward-note--title">Advertising, Editorial and Terms Apply Disclosure</div>
            <div>
              Uthrive has partnered with CardRatings for our coverage of credit card products. Uthrive and CardRatings
              may receive a commission from card issuers.
              <br />
              <br />
              Some or all of the card offers that appear on the website are from advertisers. Also, compensation may
              impact how and where links appear on this site and this site does not include all financial companies or
              all available financial offers.
              <br />
              <br />
              Opinions, reviews, analyses & recommendations are the author&apos;s alone, and have not been reviewed,
              endorsed or approved by any other entities.
              <br />
              <br />
              *Please review details about the offer, benefits and qualification criteria on the issuer&apos;s card
              application page carefully before submitting your card application, as they are subject to change at
              issuer&apos;s sole discretion.
            </div>
          </div>
          <CustomButton onClick={() => setOpenModal(!openModal)}>Close</CustomButton>
        </ModalCustom>
        <ScrollToTop />
      </div>
    </>
  );
};
