import './style.scss';

type props = {
  name: string;
  reward: string;
  logo: string;
  backgroundLogo: string;
  handleClickItem: () => void;
};

export const LatestOfferItem = ({ name, reward, logo, handleClickItem, backgroundLogo }: props) => {
  return (
    <>
      <div className="latest-offer-item-container" onClick={handleClickItem}>
        {logo ? <img src={logo} alt="logo" className="latest-offer-item-logo" /> : ''}
        <div className="latest-offer-item-info-container">
          <div className="name">{name}</div>
          <div className="reward">{reward}</div>
        </div>
      </div>
    </>
  );
};
