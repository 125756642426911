import benefit1 from 'assets/images/benefit-1.png';
import benefit2 from 'assets/images/benefit-2.png';
import benefit3 from 'assets/images/benefit-3.png';
import benefit4 from 'assets/images/benefit-4.png';
import active1 from 'assets/images/img-offer-tile-01.png';
import active2 from 'assets/images/img-offer-tile-02.png';
import active3 from 'assets/images/img-offer-tile-03.png';
import active4 from 'assets/images/img-offer-tile-04.png';
import active5 from 'assets/images/img-offer-tile-05.png';
import active6 from 'assets/images/img-offer-tile-06.png';
import active7 from 'assets/images/img-offer-tile-07.png';
import active8 from 'assets/images/img-offer-tile-08.png';
import activeMore from 'assets/images/img-offer-tile-more.png';
import coupon1 from 'assets/images/coupon-logo-01.png';
import coupon2 from 'assets/images/coupon-logo-02.png';
import coupon3 from 'assets/images/coupon-logo-03.png';
import coupon4 from 'assets/images/coupon-logo-04.png';
import coupon5 from 'assets/images/coupon-logo-05.png';
import coupon6 from 'assets/images/coupon-logo-06.png';
import coupon7 from 'assets/images/coupon-logo-07.png';

export const premiumBenefits = [
  {
    title: 'Maximize rewards of your choice',
    desc: 'Maximize rewards that fit your goals - such as Delta miles, Marriott points or just cash back.',
    img: benefit1
  },
  {
    title: 'Unlimited Best Card Advice',
    desc: 'Know the best card for every purchase and compare rewards on your cards, so you can be sure.',
    img: benefit2
  },
  {
    title: 'Choose your own rewards value ',
    desc: 'Assign your own value for point or mile earned from credit cards for accurate estimation',
    img: benefit3
  },
  {
    title: 'Keep favorite stores at fingertips',
    desc: 'Know in two taps best cards and offers for your most frequent merchants. ',
    img: benefit4
  }
];

export const premiumActiveOffers = [
  { url: active7 },
  { url: active8 },
  { url: active3 },
  { url: active4 },
  { url: active2 },
  { url: active5 },
  { url: active6 },
  { url: active1 },
  { url: activeMore }
];

export const premiumDigitalCoupons = [
  { title: 'Target', url: coupon1 },
  { title: 'Walgreens', url: coupon2 },
  { title: 'Rite Aid', url: coupon3 },
  { title: 'BJs', url: coupon4 },
  { title: 'Sprouts', url: coupon5 },
  { title: 'Publix', url: coupon6 },
  { title: 'ShopRite', url: coupon7 }
];

export const activatedOffersOptions = [
  { value: 'FAVORITE', label: 'Favorites' },
  { value: 'RECENTLY_ADDED', label: 'Recent added' },
  { value: 'EXPIRING_SOON', label: 'Expiring soon' },
  { value: 'EXPIRED', label: 'Expired' }
];

export const ACTIVE_STATUS = ['active', 'trialing', 'canceling'];

export const premiumComments = [
  {
    initial: 'K',
    name: 'Kunal M.',
    days: '9',
    desc: 'Loved the suprise <b>$100 cashback</b> on my hotel booking - automatic savings made my day.'
  },
  {
    initial: 'S',
    name: 'Sam G.',
    days: '18',
    desc: 'Scored <b>$10 back</b> on ESPN subscription from Amex - effectively paid $1.99 instead of $11.99, Very cool!'
  },

  {
    initial: 'J',
    name: 'John D.',
    days: '21',
    desc: 'Enjoyed <b>$17 credit</b> on last night&#39;s family dinner with automatic savings on my Chase card - loyal customer!'
  },
  {
    initial: 'C',
    name: 'Christine F.',
    days: '21',
    desc: 'Thrilled with <b>$22 back</b> on my Citi card for Nike sneakers - 10X savings on what I paid monthly, totally worth it.'
  }
];

export const FUNDING_TYPES = ['debit', 'prepaid', 'unknown'];
