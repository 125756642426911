import { Fragment, useCallback, useEffect, useState } from 'react';
import './style.scss';
import { compareRewards, getOffersMerchantDetails } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompareCardType, IOfferMerchantDetails } from 'types';
import CurrencyInput from 'react-currency-input-field';
import { debounce } from 'lodash';
import tickIcon from 'assets/images/tick.jpg';
import { fixMoney } from 'utils';
import chevLeft from 'assets/images/chevron-left.svg';
import checkIcon from 'assets/images/green-check-icon.svg';
// import checkIcon from 'assets/images/uthrive-icon-success.png';
import { CustomButton, ModalCustom } from 'components';

const CompareCard = ({ data }: { data: CompareCardType }) => {
  const [showHowItWork, setShowHowItWork] = useState(false);
  const [isOpenTC, setIsOpenTC] = useState(false);

  const rewardOffer = data?.offers?.find((offer) => offer?.offerType === 'reward');
  const cashbackOffer = data?.offers?.find((offer) => offer?.offerType === 'cashback');
  const bankOffer = data?.offers?.find((offer) => offer?.offerType === 'bank_offer');

  const handleGetOffer = () => {
    window.open(cashbackOffer?.offerMerchantAffiliateUrl, '_blank');
  };

  return (
    <div className="compare-item">
      <div className="compare-card-container">
        <div className="compare-card-info">
          <div className="compare-card-title">{`${data?.cardNameFull} ${data.accountMask ? '-' + data.accountMask : ''}`}</div>
          <div className="compare-card-info-container">
            <img src={data.cardImageUrl} alt="card" />
            <div>
              <div className="compare-card-info-text">Reward you earn</div>
              <div className="compare-card-info-reward">${fixMoney(rewardOffer?.rewardAmount || 0)}</div>
            </div>
          </div>
        </div>

        <div className="compare-card-offers-container">
          <div className="compare-card-offers-tag">+ Additional Savings with Offers</div>
          <div className="compare-card-offers-rewards">
            <div className="compare-card-offers-rewards-desc">{cashbackOffer?.offerShortDesc}</div>
            <div className="compare-card-offers-rewards-amount">${fixMoney(cashbackOffer?.rewardAmount || 0)}</div>
          </div>
          <div className="compare-card-offers--how-it-work">
            <div className="text-btn" onClick={() => setShowHowItWork(!showHowItWork)}>
              How it works?
            </div>
            {showHowItWork && (
              <div className="btn-close-details" onClick={() => setShowHowItWork(!showHowItWork)}>
                X
              </div>
            )}
          </div>
          {showHowItWork && (
            <div className="how-it-work-text">
              Simply tap on &apos;Get Offer&apos; to visit merchant site and make purchases. You will get cash back from
              Uthrive on qualifying purchase amount, which you can redeem into your checking or savings account via
              PayPal. You will earn this, in addition to rewards or cashback offered by your credit card.
            </div>
          )}
          {bankOffer && (
            <div>
              <div className="devider" />
              <div className="compare-card-premium-container">
                <div className="compare-card-premium">
                  <img src={tickIcon} alt="premium" className="icon-check" />
                  <div className="compare-card-premium-text">Premium Offer Activated</div>
                </div>
                <div className="compare-card-premium-amount">${fixMoney(bankOffer.rewardAmount)}</div>
              </div>
              <div className="compare-card-bank-offer-desc">{bankOffer.offerShortDesc}</div>
              <div className="compare-card--tc-btn" onClick={() => setIsOpenTC(!isOpenTC)}>
                T&C Apply
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="compare-card--total-savings">
        <img src={checkIcon} alt="check" />
        <div>Total Savings: ${fixMoney(data?.totalRewardAmount)}</div>
      </div>
      <CustomButton className="compare-card--get-offer-btn" onClick={handleGetOffer}>
        Get offers
      </CustomButton>
      <ModalCustom
        isOpen={isOpenTC}
        className="custom-modal-offer-tc"
        toggle={() => setIsOpenTC(!isOpenTC)}
        onClose={() => setIsOpenTC(!isOpenTC)}
        isSupportClose>
        <div className="offerDescription-container">{bankOffer?.offerDescription}</div>
      </ModalCustom>
    </div>
  );
};

export const CompareRewards = () => {
  const params = useParams<{ merchantName: string }>();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  console.log(params);
  const merchantName = params?.merchantName || '';
  const { setAppLoading } = useAppStore();

  const [cards, setCards] = useState<CompareCardType[] | null>([]);
  const [amount, setAmount] = useState<string | undefined>('');
  const [offerMerchant, setOfferMerchant] = useState<IOfferMerchantDetails | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    fetchMerchantDetail();
  }, [merchantName]);

  const fetchMerchantDetail = async () => {
    try {
      setAppLoading(true);
      const params = { merchantName: merchantName };
      const resp = await getOffersMerchantDetails(params);
      setOfferMerchant(resp);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleBackBtn = () => {
    navigate('/offers', { replace: true });
  };

  const fetchCards = async (value: string | undefined) => {
    try {
      if (!merchantName || (value !== '0' && !value)) return;
      setAppLoading(true);
      const params = { merchantName: merchantName, spendAmount: value };
      const resp = await compareRewards(params);
      setCards(resp?.data);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
      navigate('/cards', { replace: true });
    }
  };

  const handleChangeAmount = useCallback(
    debounce(async (val: string | undefined) => {
      try {
        await fetchCards(val);
        if (val && parseInt(val) > 100) setIsOpen(true);
      } catch (err) {
        console.log(err);
      }
    }, 500),
    []
  );

  const handleChange = useCallback(
    (value: string | undefined) => {
      setIsOpen(false);
      if (value === amount) return;
      setAmount(value);
      handleChangeAmount(value);
    },
    [amount]
  );

  if (!merchantName) return <div className="compare-rewards-container"></div>;

  return (
    <div className="compare-rewards-container">
      <button className="compare-rewards-btnBack" onClick={handleBackBtn}>
        <img src={chevLeft} alt="chevron-left" />
        Back
      </button>
      <div className="compare-rewards-header">
        <div className="compare-rewards-header--title">Compare rewards on all your cards</div>
        {!!offerMerchant && (
          <div className="compare-rewards-header--merchant-container">
            {/* <img src={offerMerchant?.merchant.merchantImgUrl} alt="merchant" /> */}
            <img
              src={offerMerchant?.merchant?.merchantImgUrl || offerMerchant?.merchant?.categoryImgUrl}
              alt="merchant"
            />
            <div>Simply tell us how much you plan to spend?</div>
          </div>
        )}
        <div className="compare-rewards-header--merchant-name">
          {offerMerchant?.merchant.offerMerchant || merchantName}
        </div>
        <div className="compare-rewards-header--input">
          <CurrencyInput prefix="$" placeholder="$0" decimalsLimit={2} value={amount} onValueChange={handleChange} />
          <div className="compare-rewards-header--input-lable">Enter amount</div>
        </div>
      </div>
      <div className="compare-rewards-body">
        <div className="compare-rewards-contents">
          {cards?.map((c, idx) => {
            if (idx === 0) {
              return (
                <Fragment key={idx}>
                  <div className="tag tag-yellow">Best card for {merchantName}</div>
                  <CompareCard data={c} />
                </Fragment>
              );
            } else if (idx === 1) {
              return (
                <Fragment key={idx}>
                  <div className="tag">Other cards you own</div>
                  <CompareCard data={c} />
                </Fragment>
              );
            }
            return <CompareCard data={c} key={idx} />;
          })}

          {isOpen && (
            <div className={`compare-suggestion ${isOpen ? 'active' : 'inactive'}`}>
              <div className="compare-suggestion-topModal">
                <span className="tag">New</span>
                <span className="close" onClick={() => setIsOpen(!isOpen)}>
                  ×
                </span>
              </div>
              <div className="compare-suggestion-bodyModal">
                <h3>
                  <span className="highlight">{user?.firstName || 'Hi there'}</span>, Looking to spend ${amount} at{' '}
                  {offerMerchant?.merchant.offerMerchant}?
                </h3>
                <div className="desc">
                  <p>Check out our new recommended cards for you. You will:</p>
                  <ul>
                    <li>
                      Up to 100,000 points or $900 cash back as welcome offer by fully or partly qualifying with this
                      spend
                    </li>
                    <li>Also get all the rewards + savings</li>
                  </ul>
                </div>

                <CustomButton
                  onClick={() => {
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                    navigate('/cards/');
                  }}>
                  View recommended cards
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
