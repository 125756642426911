import { useEffect, useRef } from 'react';
import * as io from 'socket.io-client';
import { useAuthStore } from 'store';

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_IO_REDEEM_PAYPAL;

const transports = ['polling'];

export const useSocketRedeem = () => {
  const { token } = useAuthStore();
  const socketRef = useRef<any>(null);

  useEffect(() => {
    // Create a new socket instance
    const socketInstance = io(SOCKET_SERVER_URL, {
      transports,
      path: '/socket.io',
      query: { authorization: `Bearer ${token}` }
    });

    socketInstance.on('connect', () => {
      console.log('connect socket');
      socketInstance.emit('listenUser');
    });

    socketInstance.on('gateway-error', (err: any) => {
      console.log('err socket: ', err);
    });

    socketInstance.on('disconnect', () => {
      console.log('disconnect socket');
    });

    // Set the socket in state
    socketRef.current = socketInstance;

    // Clean up on unmount
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return socketRef.current;
};
