import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import './style.scss';
import {
  CustomButton,
  EarnedRewardsBoxItem,
  ModalCustom,
  PopoverDownloadApp,
  TransactionEarnedReward
} from 'components';
import { getEarnedRewardDetail, getMoreEarnedRewards } from 'services';
import { EarnedRewardDetailType, SeeMoreEarnedDataType } from 'types';
import { useAppStore, useAuthStore } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { formatMoney } from 'utils';
import iconPre from 'assets/images/back.png';
import iconNext from 'assets/images/next.png';
import chevLeft from 'assets/images/chevron-left.svg';
import chat from 'assets/images/icon-chat.png';

type activeFilter = 'year' | 'month';
type activeFilterReward = 'missed' | 'recent';

export const EarnedRewardsDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userConfig } = useAuthStore();
  const { setAppLoading } = useAppStore();
  const [activeButton, setActiveButton] = useState<activeFilter>('year');
  const [activeFilterReward, setActiveFilterReward] = useState<activeFilterReward>('missed');
  const [date, setDate] = useState(moment());
  const [earnedRewardsData, setEarnedRewardsData] = useState<EarnedRewardDetailType | null>(null);
  const [loading, setLoading] = useState(false);
  const [seeMoreEarned, setSeeMoreEarned] = useState<SeeMoreEarnedDataType | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate('/rewards-earned', { replace: true });
    }
    fetchMoreMissed();
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [activeButton, date, id]);

  const fetchData = async () => {
    try {
      if (!id) return;
      setAppLoading(true);
      let params = {};
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1 };
      }
      const resp = await getEarnedRewardDetail(params, id);
      setEarnedRewardsData(resp.data);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };
  const fetchMoreMissed = async () => {
    try {
      if (!id) return;
      setLoading(true);
      const params = { excludeAccountId: id };
      const resp = await getMoreEarnedRewards(params);
      setSeeMoreEarned(resp.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleButtonClick = (buttonType: activeFilter) => {
    setActiveButton(buttonType);
    setDate(moment());
  };

  const handleButtonRewardClick = (buttonType: activeFilterReward) => {
    setActiveFilterReward(buttonType);
  };

  const handleAddMonth = () => {
    if (moment(date).month() === moment().month()) {
      return;
    }
    const temp = moment(date).add(1, 'months');
    setDate(temp);
  };

  const handleSubtractMonth = () => {
    const temp = moment(date).subtract(1, 'months');
    setDate(temp);
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const transactionData = useMemo(() => {
    switch (activeFilterReward) {
      case 'missed':
        return earnedRewardsData?.mostEarnedTransactions || [];
      case 'recent':
        return earnedRewardsData?.mostRecentTransactions || [];
      default:
        return [];
    }
  }, [activeFilterReward, earnedRewardsData]);

  const isShowByAmount = earnedRewardsData && earnedRewardsData?.mostEarnedTransactions?.length > 0;
  const isShowMostRecent = earnedRewardsData && earnedRewardsData?.mostRecentTransactions?.length > 0;

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  return (
    <>
      <div className="rewards-missed-container">
        <button className="rewards-missed-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <img className="chat-btn" src={chat} alt="chat" onClick={() => setOpenInfoModal(true)} />
        <div className="rewards-missed-header">
          <h2 className="rewards-missed-header-title">Earned Rewards</h2>
        </div>
        <div className="rewards-missed-box">
          <div className="rewards-missed-filter">
            <button
              className={`rewards-missed-filter-left ${activeButton === 'year' ? 'active' : ''}`}
              onClick={() => handleButtonClick('year')}>
              Year
            </button>
            <button
              className={`rewards-missed-filter-right ${activeButton === 'month' ? 'active' : ''}`}
              onClick={() => handleButtonClick('month')}>
              Month
            </button>
          </div>
          {activeButton === 'month' && (
            <div className="rewards-missed-filter--month">
              <img src={iconPre} alt="icon" onClick={handleSubtractMonth} />
              <div className="rewards-missed-filter--month-value">{date.format("MMM'YY")}</div>
              <img
                src={iconNext}
                alt="icon"
                onClick={handleAddMonth}
                className={`${moment(date).month() === moment().month() ? 'maxed' : ''}`}
              />
            </div>
          )}

          <div className="rewards-missed-flexbox">
            <div className="infobox">
              <h2>{`${earnedRewardsData?.account?.name}${earnedRewardsData?.account?.accountMask ? ` - ${earnedRewardsData?.account?.accountMask}` : ''}`}</h2>
              <div className="infobox-reward">
                <div className="title">
                  <div className="rewards-image-container">
                    <img src={earnedRewardsData?.account?.accountImageUrl} alt="icon" />
                  </div>
                </div>
                <div className="infobox-reward-sm">
                  <div className="infobox-reward-sm-item">
                    <span className="infobox-reward-sm-title">
                      Net
                      <br />
                      Spent
                    </span>
                    <p className="infobox-reward-sm-price">
                      ${formatMoney(earnedRewardsData?.account?.totalSpent || 0)}
                    </p>
                  </div>
                  <div className="infobox-reward-sm-item">
                    <span className="infobox-reward-sm-title">
                      Earned
                      <br />
                      Rewards
                    </span>
                    <p className="infobox-reward-sm-price missed">
                      ${formatMoney(earnedRewardsData?.account?.totalRewards || 0)}
                    </p>
                  </div>
                </div>
              </div>
              <h2 className="rewards-missed-transactitle">Your Top Transactions</h2>
              <div className="infobox-filter">
                {isShowByAmount && (
                  <button
                    className={`infobox-filter-left ${activeFilterReward === 'missed' ? 'active' : ''}`}
                    onClick={() => handleButtonRewardClick('missed')}>
                    By Amount
                  </button>
                )}
                {isShowMostRecent && (
                  <button
                    className={`infobox-filter-right ${activeFilterReward === 'recent' ? 'active' : ''}`}
                    onClick={() => handleButtonRewardClick('recent')}>
                    Most Recent
                  </button>
                )}
              </div>
              {transactionData?.length !== 0 && (
                <div className="infobox-label">
                  <span>Transaction</span>
                  <span>Earned rewards</span>
                </div>
              )}
              {transactionData?.map((item, key) => {
                return <TransactionEarnedReward cardId={id || ''} data={item} key={key} />;
              })}
              {transactionData?.length === 0 && (
                <>
                  <div className="rewards-missed--text">
                    Either there are no purchase in this month, or transactions are not received from your bank.
                    <br />
                    <br />
                    Try refreshing your cards to view updated transactions
                    <CustomButton id="popover-qr-code" onClick={() => setShowPopover(!showPopover)}>
                      Refresh Your Card Links
                    </CustomButton>
                  </div>
                  <PopoverDownloadApp
                    target="popover-qr-code"
                    isOpen={showPopover}
                    handleDone={() => setShowPopover(false)}
                    title="Refresh your card links on Uthrive App"
                    placement="top"
                    qrWidth="150px"
                    modalSize="bg"
                  />
                </>
              )}
            </div>
            <div className="cardbox">
              <div className="cardbox-title">
                <h2>See More Earned Rewards</h2>
              </div>
              {seeMoreEarned?.list?.map((item, key) => {
                return (
                  <EarnedRewardsBoxItem
                    data={item}
                    key={key}
                    handleClick={() => navigate(`/rewards-earned-detail/${item?.id}`)}
                    isSeeMore
                  />
                );
              })}
              {loading &&
                [1, 2, 3].map((idx) => {
                  return <div key={idx} className="missed-reward-loading" />;
                })}
            </div>
          </div>
        </div>
      </div>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Have a question?</div>
          <div>
            Get help from experts at Uthrive. Typically responds in 2-5 hrs.
            <br />
            <br /> For urgent help, please call <span className="highlight">{userConfig?.callContactNumber}</span>{' '}
            {userConfig?.callContactTime}.
          </div>
        </div>
        <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
      </ModalCustom>
    </>
  );
};
