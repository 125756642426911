export const validatePhoneNumber = (number: string) => {
  const numberConvert = number.replace(/-/gi, '');
  const reg = /^[0-9]{1,10}$/;
  const checking = reg.test(numberConvert);
  if (numberConvert && numberConvert.replace(/_/gi, '').length === 10) {
    return checking;
  }

  return false;
};

export const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const capitalize = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

export const convertToShortForm = (num: number) => {
  if (num >= 1000) {
    const shortNum = (Math.floor(num / 100) / 10).toFixed(1);
    // Remove the trailing '.0' for whole numbers
    return shortNum.endsWith('.0') ? shortNum.slice(0, -2) + 'K' : shortNum + 'K';
  }
  return num.toString();
};

export const getRewardValue = (type: string, value: string) => {
  switch (type) {
    case 'FLAT':
      return value ? `$${formatMoney(convertToShortForm(parseFloat(value)))} back` : '__';
    case 'PERCENTAGE':
      return value ? `${convertToShortForm(parseFloat(value))}% back` : '__';
    case 'EXTRA_MILES':
      return value ? `${convertToShortForm(parseFloat(value))} Extra mi` : '__';
    case 'EXTRA_POINTS':
      return value ? `${convertToShortForm(parseFloat(value))} Extra pts` : '__';

    default:
      return '';
  }
};

export const formatMoney = (value: string | number) => {
  if (!value) return 0;
  const roundedNumber = Math.round(typeof value === 'string' ? parseFloat(value) : value);
  const formattedNumber = roundedNumber.toLocaleString();
  return formattedNumber;
};

export const fixMoney = (value: string | number) => {
  if (!value) return 0;
  return typeof value === 'string'
    ? parseFloat(value)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : value?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const getAddressHelper = (data: any) => {
  const addressDetails = {
    streetAddress: '',
    streetNumber: '',
    apartmentAddress: '',
    city: '',
    state: '',
    zipCode: ''
  };
  if (!data) return addressDetails;
  addressDetails.streetAddress =
    (data?.address_components.find((c: any) => c.types.includes('route')) || {}).long_name || '';
  addressDetails.streetNumber =
    (data?.address_components.find((c: any) => c.types.includes('street_number')) || {}).long_name || '';

  addressDetails.city = (data?.address_components.find((c: any) => c.types.includes('locality')) || {}).long_name || '';

  addressDetails.state =
    (data?.address_components.find((c: any) => c.types.includes('administrative_area_level_1')) || {}).short_name || '';

  addressDetails.zipCode =
    (data?.address_components.find((c: any) => c.types.includes('postal_code')) || {}).long_name || '';

  return addressDetails;
};

export const sleepFunc = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
