import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import { CustomButton } from 'components/Button';
import logo from 'assets/images/logo.svg';

type props = {
  isOpen: boolean;
  firstname: string;
  handleContinue: () => void;
};

export const ModalConsentCapture = ({ firstname, isOpen = false, handleContinue }: props) => {
  return (
    <ModalCustom isOpen={isOpen} className="custom-modal-consent-capture" isSupportClose={false}>
      <div className="custom-modal-consent-capture-container">
        <img src={logo} alt="logo" />
        {/* <div className="custom-modal-consent-capture-title">{`Welcome, ${firstname}! Your account is successfully created.`}</div> */}
        <div className="custom-modal-consent-capture-title">We&lsquo;re about to create your Account.</div>
        <div className="custom-modal-consent-capture-subtitle">Please review and confirm.</div>
        <div className="custom-modal-consent-capture-desc">
          <div className="terms-conditions">
            {`By submitting this registration form, I agree with Uthrive’s `}
            <span
              className="btn-text"
              onClick={() => {
                window.open(`https://www.uthrive.club/terms-and-conditions/`, '_blank');
              }}>
              Terms & Conditions
            </span>
            {` and `}
            <span
              className="btn-text"
              onClick={() => {
                window.open(`https://www.uthrive.club/privacy-policy/`, '_blank');
              }}>
              Privacy Policy
            </span>
            {`. I understand that I am providing written instructions in accordance with the Fair Credit Reporting Act and other applicable law for Uthrive to request and receive information about me from third parties, including but not limited to a copy of my consumer report and score from consumer reporting agencies, at any time for so long as I have an active Uthrive account. I further authorize Uthrive to retain a copy of my credit information for it’s use and to match with credit products.`}
          </div>
        </div>
        <CustomButton onClick={handleContinue}>Agree & Confirm</CustomButton>
      </div>
    </ModalCustom>
  );
};
