import React from 'react';
import nextIcon from 'assets/images/next.png';
import './style.scss';
import { MissedRewardType } from 'types';
import { formatMoney } from 'utils';

type props = {
  data: MissedRewardType;
  handleClickItem?: () => void;
};

export const MissedRewardsBoxItem = ({ data, handleClickItem }: props) => {
  return (
    <div className="missed-reward-item" onClick={handleClickItem ? handleClickItem : undefined}>
      <h3 className="missed-reward-title">{data?.category}</h3>
      <div className="missed-reward-infoBox">
        <div className="missed-reward-image">
          <img src={data?.categoryImageUrl} alt="category" />
        </div>
        <div className="missed-reward-sm">
          <div className="missed-reward-sm-item">
            <span className="missed-reward-sm-title">
              Net
              <br />
              Spent
            </span>
            <p className="missed-reward-sm-price">${formatMoney(data?.totalSpend)}</p>
          </div>
          <div className="missed-reward-sm-item">
            <span className="missed-reward-sm-title">
              Missed
              <br />
              Rewards
            </span>
            <p className="missed-reward-sm-price">${formatMoney(data?.totalMissedReward)}</p>
          </div>
        </div>
      </div>
      <div className="missed-reward-nextIcon">
        <img src={nextIcon} alt="next"></img>
      </div>
    </div>
  );
};
