import { ModalCustom } from 'components/ModalCustom';
import logo from 'assets/images/logo.svg';
import './style.scss';

type props = {
  onClose: () => void;
  isOpen: boolean;
  handleClickCTA: () => void;
  socialName: string;
};

export const ModalExistAccount = ({ onClose, isOpen, handleClickCTA, socialName }: props) => {
  return (
    <ModalCustom
      onClose={onClose}
      toggle={onClose}
      isOpen={isOpen}
      className="modal-blur modal-lg modal-authen-methods">
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div className="modal-exist-account">
        <div className="modal-exist-account--title">Account Already Exists.</div>
        <div className="modal-exist-account--text">
          An account already exists associated with Email on your <span>{socialName}</span> login, please sign in.
        </div>
        <button className="modal-exist-account--btn" onClick={handleClickCTA}>
          Sign in
        </button>
      </div>
    </ModalCustom>
  );
};
