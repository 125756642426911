import React, { useEffect, useState } from 'react';
import './style.scss';
import logoUthrive from 'assets/images/Uthrive-logo-short-web.png';
import logo from 'assets/images/logo.svg';
import add from 'assets/images/add.png';
import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { extensionUninstallFeedback, getShadowId } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { linkDownLoadExtension, listIssue } from 'const';
import { getLinkDownloadExtensionByBrowser } from 'utils/extension';

export const UninstallExtension = () => {
  const navigate = useNavigate();

  const { user } = useAuthStore();
  const { setAppLoading } = useAppStore();

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [otherValue, setOtherValue] = useState('');

  const handleCheck = (value: string) => {
    if (checkedItems.includes(value)) {
      const temp = checkedItems.filter((e) => e !== value);
      setCheckedItems(temp);
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  useEffect(() => {
    if (otherValue && !checkedItems.includes('other')) {
      setCheckedItems([...checkedItems, 'other']);
    }
  }, [otherValue]);

  const handleSubmit = async () => {
    try {
      setAppLoading(true);
      const params = {
        options: checkedItems,
        comments: otherValue,
        shadowUserId: ''
      };
      if (user?.userId) {
        params.shadowUserId = user.userId;
      } else {
        const data = await getShadowId();
        params.shadowUserId = data?.shadowUserId;
      }

      await extensionUninstallFeedback(params);
      setCheckedItems([]);
      setOtherValue('');
      setAppLoading(false);
      navigate('/uninstall-extension/thanks');
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
    }
  };

  return (
    <div className="uninstall-extension-wrapper">
      <div className="uninstall-extension-wrapper--header">
        <div className="uninstall-extension-wrapper--header-logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        <div className="uninstall-extension-wrapper--header-button">
          <a
            href={linkDownLoadExtension.find((e) => e.key === getLinkDownloadExtensionByBrowser())?.value}
            target="_blank"
            rel="noreferrer">
            <button>
              <img src={add} alt="icon" />
              Reinstall Uthrive
            </button>
          </a>
        </div>
      </div>
      <div className="uninstall-extension-wrapper--body">
        <div className="uninstall-extension-wrapper--body-title">
          <h1>Help us improve.</h1>
          <h5>Why did you uninstall Uthrive? Check all that apply.</h5>
        </div>
        <div className="uninstall-extension-wrapper--body-contents">
          <div className="uninstall-extension--body-logo">
            <div className="uninstall-extension--body-wrapper">
              <img src={logoUthrive} alt="logo" />
              <div className="uninstall-extension--body-logo-text">
                Did you know, on average consumers miss $200-$800 rewards and cashback by not using the right credit
                cards?
                <br />
                Get automatic advice on using your best card + much more.
              </div>
            </div>
          </div>
          <div className="uninstall-extension-wrapper--body-listItem">
            {listIssue.map((e) => {
              return (
                <div className="checkbox-wrapper" key={e.value}>
                  <Input
                    addon
                    aria-label="Checkbox for following text input"
                    type="checkbox"
                    value={e.value}
                    onChange={() => {
                      handleCheck(e.value);
                    }}
                  />
                  <label>{e.label}</label>
                </div>
              );
            })}
            <textarea
              value={otherValue}
              onChange={(e) => setOtherValue(e.target.value)}
              placeholder="How else can we improve Uthrive?"
              className="uninstall-extension--text-area"
            />
            <button
              disabled={checkedItems?.length === 0 || (checkedItems.includes('other') && otherValue === '')}
              onClick={handleSubmit}
              className="uninstall-extension--btn-submit">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
