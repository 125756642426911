import nextIcon from 'assets/images/next.png';
import './style.scss';
import { MissedTransactionType } from 'types';
import moment from 'moment';
import { fixMoney } from 'utils';
import { useNavigate } from 'react-router-dom';

type props = {
  data: MissedTransactionType;
  handleClick?: () => void;
  category: string;
  isYourCard: boolean;
};

export const TransactionRewardItem = ({ data, handleClick, category, isYourCard }: props) => {
  const navigate = useNavigate();
  return (
    <div className="transaction-item" onClick={handleClick ? handleClick : undefined}>
      <div className="transaction-card">
        <img src={data?.accountImageUrl} alt="card" />
        <span className="name">{`${data?.cardNameShort}${data?.accountMask ? ` - ${data?.accountMask}` : ''}`}</span>
      </div>
      <div className="transaction-infobox">
        <div className="info">
          <span className="info-name">{data?.transactionName}</span>
          <span className="info-date">Date: {moment(data?.transactionDate)?.format('ddd, MM/DD/YYYY')}</span>
          <span className="info-spent">Spent: ${fixMoney(data?.transactionAmount)}</span>
        </div>
        <div className="reward">
          <span>${fixMoney(data?.missedReward)}</span>
          <img src={nextIcon} alt="next"></img>
        </div>
      </div>
      <span
        className="transaction-report"
        onClick={() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
          navigate(
            `/rewards-missed/report-issue/${category}/${data?.txnId}/?isYourCard=${isYourCard}&isReported=${data.isSubmittedWrongCategorizationReport || false}`
          );
        }}>
        {data?.isSubmittedWrongCategorizationReport ? 'Issue Reported!' : 'Report Issues'}
      </span>
    </div>
  );
};
