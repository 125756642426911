import './style.scss';
import { LoaderThreeDots } from 'components/LoaderThreeDots';

type props = {
  loadingText?: string;
};

export const AppLoadingThreedots = ({ loadingText = '' }: props) => {
  return (
    <>
      <div className="threedots-container">
        <LoaderThreeDots />
        <div className="loading-text">
          {loadingText ? (
            loadingText
          ) : (
            <>
              Setting things up for you,
              <br />
              just a moment please..
            </>
          )}
        </div>
      </div>
      <div className="threedots-backdrop" />
    </>
  );
};
