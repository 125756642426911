import React, { useEffect } from 'react';
import './style.scss';
import chevLeft from 'assets/images/chevron-left.svg';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'store';

export const PolicyPage = () => {
  const navigate = useNavigate();
  const { setAppLoading } = useAppStore();

  useEffect(() => {
    setAppLoading(true);
  }, []);

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  return (
    <div className="policy">
      <div className="policy-container">
        {' '}
        <button className="compare-rewards-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <iframe
          onLoad={() => setTimeout(() => setAppLoading(false), 1500)}
          title="privacy policy"
          id="idIframe"
          className="policy-frame"
          src="https://app.termly.io/document/privacy-policy/18c45e5a-4cfe-414c-90dc-cb0bd8b1ff85"
          width="100%"></iframe>
      </div>
    </div>
  );
};
